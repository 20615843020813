import React from 'react'
import { Flex, Text, Box } from '@chakra-ui/react';
import { Icon } from '@iconify/react';

export default function Footer() {
    const handleNavigate = (path) => {
        window.open(path, '_blank')
    }
    return (
        <Flex
            width={'100%'}
            justifyContent={'center'}

        >
            <Flex
                width={'80%'}
                alignItems={'center'}
                justifyContent={'space-between'}
                pb={10}
                mt={10}
                color={'silver'}
            >
                <Flex>
                    <Box
                        sx={{
                            transform: 'scale(0.95)',
                            transition: 'box-shadow 0.5s, transform 0.5s',
                            '&:hover': {
                                transform: 'scale(1.3)',
                            },
                        }} as={'button'}
                        onClick={() => handleNavigate('https://www.instagram.com/zyll')}
                    >
                        <Icon icon={'mdi:instagram'} width={40} height={40} />
                    </Box>
                    <Box
                        sx={{
                            transform: 'scale(0.95)',
                            transition: 'box-shadow 0.5s, transform 0.5s',
                            '&:hover': {
                                transform: 'scale(1.3)',
                            },
                        }} as={'button'}
                        onClick={() => handleNavigate('https://www.x.com/ZyllHamood')}
                    >
                        <Icon icon={'mdi:twitter'} width={40} height={40} />
                    </Box>
                    <Box
                        sx={{
                            transform: 'scale(0.95)',
                            transition: 'box-shadow 0.5s, transform 0.5s',
                            '&:hover': {
                                transform: 'scale(1.3)',
                            },
                        }} as={'button'}
                        onClick={() => handleNavigate('https://www.github.com/ZyllHamood')}
                    >
                        <Icon icon={'mdi:github'} width={40} height={40} />
                    </Box>
                    <Box
                        sx={{
                            transform: 'scale(0.95)',
                            transition: 'box-shadow 0.5s, transform 0.5s',
                            '&:hover': {
                                transform: 'scale(1.3)',
                            },
                        }} as={'button'}
                        onClick={() => handleNavigate('https://t.me/ZyllHamood')}
                    >
                        <Icon icon={'uil:telegram'} width={40} height={40} />
                    </Box>

                </Flex>
                <Flex>
                    <Text
                        fontSize={18}
                    >Copyright © 2024 By Zyll</Text>
                </Flex>
            </Flex>
        </Flex>
    )
}
