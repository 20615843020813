import React, { useState, useRef } from 'react'
import { Box, Flex, Text, Input, Button, Textarea } from '@chakra-ui/react';
import { bk1 } from '../../assets/colors';
export default function DeleteRepeated() {
    const [result, setResult] = useState('');
    const [all, setAll] = useState([]);
    const [list, setList] = useState([]);
    const [newList, setNewList] = useState([]);
    const handleButton = () => {
        setResult('');

        setResult(newList.join('\n'));
    }
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            const lines = text.split('\n');
            setList(lines.join('\n'));
        }
        reader.readAsText(file);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://api.zyll.shop/delete-repeated/', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            setNewList(result.new_list);

        } catch (error) {
            console.error('Error:', error);
        }

    };
    return (
        <>
            <Flex width='100%' justifyContent='center' color={'#fff'}>
                <Flex
                    width={{ base: '90%', md: '40%' }}
                    height='540px'
                    mt='20px'
                    flexDir='column'
                    borderRadius='8px'
                    justifyContent='start'
                    alignItems='center'
                    flexDirection='column'
                >
                    <Input
                        type="file"
                        onChange={handleFileUpload}
                        p={1}
                        accept=".txt*"
                        m='10px' width='96%'
                    />
                    <Textarea value={list} m='10px' width='96%' height='140px'></Textarea>
                    <Button
                        onClick={handleButton}
                        width='96%'
                        color={'#fff'}
                        bgGradient={bk1}
                        _hover={{ opacity: 0.7 }}
                    >Delete Repeated</Button>
                    {result && <Textarea m='10px' width='96%' height='310px' value={result}></Textarea>}


                </Flex>
            </Flex>
        </>
    )
}