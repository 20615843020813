import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Button, Input, useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../store/authSlice'
import { Icon } from '@iconify/react'
import { bk1, bk4 } from '../assets/colors'
export default function Register() {
    const navigate = useNavigate();
    const toast = useToast();
    const dispatch = useDispatch();
    const usernameRef = useRef('');
    const passwordRef = useRef('');
    const rePasswordRef = useRef('');
    const { isLoading, isAdmin, respRegister } = useSelector((state) => state.auth)
    const handleRegister = () => {
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        const rePassword = rePasswordRef.current.value;
        if (username === '' || password === '' || rePassword === '') {
            toast({
                title: 'Please fill all fields',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            return;
        }
        if (password !== rePassword) {
            toast({
                title: 'Passwords do not match',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            return;
        }
        const data = {
            username,
            password
        }
        dispatch(register(data))
    }
    useEffect(() => {
        if (respRegister !== null) {
            if (respRegister.username === usernameRef.current.value) {
                navigate('/users');
            }
            else {
                toast({
                    title: respRegister,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }
        }

    }, [respRegister])
    return (
        <Flex
            mt={'100px'}
            flexDir={'column'}
            borderRadius={12}

            bg={'#1B1639'}
            width={{ base: '80%', md: '26%' }}
            height={'400px'}
            boxShadow="0px 20px 60px #5343B7"
            p="6"
            alignItems={'center'}
        >
            <Text
                fontSize={30}
                fontWeight={'bold'}
                fontFamily={'Lastica'}
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                mt={6}
                letterSpacing="8px"
            >
                Register
            </Text>
            <Input
                placeholder='Username'
                mt={6}
                width={'80%'}
                color={'#fff'}
                ref={usernameRef}
            />
            <Input
                placeholder='Password'
                type='password'
                mt={5}
                color={'#fff'}
                width={'80%'}
                ref={passwordRef}
            />
            <Input
                placeholder='Re-Password'
                type='password'
                mt={5}
                color={'#fff'}
                width={'80%'}
                ref={rePasswordRef}
            />
            <Button
                colorScheme="purple"
                variant="solid"
                _hover={{ opacity: 0.7 }}
                bgGradient={bk1}
                color={'#fff'}
                _active={{ opacity: 0.7 }}
                fontWeight={'bold'}
                mt={8}
                width={'80%'}
                onClick={handleRegister}
            >{isLoading ? <Icon icon={'eos-icons:bubble-loading'} width={30} height={30} /> : 'Sign up'}</Button>
        </Flex>
    )
}
