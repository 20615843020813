import React, { useState } from 'react'
import { Flex, Text, Button, Radio, RadioGroup, Stack, Box, Textarea } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import { bk1 } from '../../assets/colors';
export default function GetProxies() {
    const [value, setValue] = useState(null);
    const [respProxies, setRespProxies] = useState(null)
    const [btnProxies, setBtnProxies] = useState('Get Proxies')
    console.log(value)
    const handleProxies = () => {

        setBtnProxies('loading ...');
        let type_proxy = "";
        if (value === "1") {
            type_proxy = "https";
        }
        else if (value === "2") {
            type_proxy = "socks4";
        }
        else if (value === "3") {
            type_proxy = "socks5";
        }

        try {
            fetch(`https://api.zyll.shop/get_proxies/${type_proxy}`)
                .then((response) => response.ok && response.json())
                .then((data) => {
                    setRespProxies(data.response);
                    //console.log(respProxies)
                    setBtnProxies('Get Proxies');
                })
        } catch (error) {
            setRespProxies('error : try again later')
            setBtnProxies('Get Proxies');
        }


    }
    return (
        <Flex
            mt={20}
            color={'#fff'}
            flexDir={'column'}
        >
            <RadioGroup onChange={setValue} value={value} bgColor={'#1B1639'} p={3} borderRadius={8}>
                <Stack direction='row'>
                    <Radio value='1'>HTTP/S</Radio>
                    <Radio value='2'>SOCKS4</Radio>
                    <Radio value='3'>SOCKS5</Radio>
                </Stack>
            </RadioGroup>
            <Button
                bgGradient={bk1}
                color={'#fff'}
                mt={5}
                _hover={{ opacity: 0.7 }}
                onClick={handleProxies}
            >
                {btnProxies}
            </Button>
            <Box height='300px' >

                <Textarea
                    mt={5}
                    height='100%'
                    bgColor='#1B1639'
                    borderColor={'#1B1639'}
                    resize='none'
                    value={respProxies && respProxies.join('\n')
                    }></Textarea>
            </Box>
        </Flex>
    )
}
