import React, { useState, useEffect, useRef } from 'react'
import { Flex, Box, Input, Text, Button, useToast, Sekleton, Skeleton } from '@chakra-ui/react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { bk1, bk2, bk3 } from '../assets/colors';
import { Icon } from '@iconify/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
export default function Profile() {
    const access_token = Cookies.get("access_token");
    const serialRef = useRef('');
    const [respSerial, setRespSerial] = useState(null)
    const { products } = useSelector((state) => state.product)
    const [loadingSerial, setLoadingSerial] = useState(false)
    const navigate = useNavigate();
    const [resp, setResp] = useState(null)
    const toast = useToast();
    useEffect(() => {
        if (resp === null) {
            fetch('https://api.zyll.shop/profile/', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then((response) => response.json())
                .then((data) => setResp(data));

        }
    }, [resp, access_token])
    const handleLogout = () => {
        Cookies.remove('access_token');
        window.location.href = '/login'
    }
    const handleUpdateSerial = () => {
        setLoadingSerial(true);
        const serial = serialRef.current.value;
        console.log('serial: ' + serial)
        const data = { serial: serial }
        fetch('https://api.zyll.shop/update-serial/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.status === 200) {
                toast({
                    title: 'Serial updated successfully',
                    status: 'success',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }
            else {
                toast({
                    title: respSerial,
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }
            setLoadingSerial(false);
        })



    }
    const naviagteNewProduct = (name) => {
        console.log(name)
        if (products !== null) {
            const product = products.find((product) => product.name === name)
            console.log(product)
            navigate(`/product/${product.id}`)
        }
    }

    return (
        <Flex
            width={{ base: '90%', md: '70%' }}
            height={'500px'}
            bg={'#1B1639'}
            borderRadius={8}
            flexDir={'column'}
            p={6}
        >

            <Flex width={'100%'} justifyContent={'space-between'}>
                <Flex justifyContent={'start'} alignItems={'center'} >
                    <Text fontSize={20} color={'#fff'}><Icon icon={'mdi:user-outline'} width={24} height={24} /></Text>
                    <Text fontSize={20} color={'#fff'} ml={2} fontWeight={'bold'}>{' '}{resp !== null ? resp.username : <Skeleton width={'100px'} height={'20px'} startColor='#351f4a' endColor='#1d2850' />}</Text>
                </Flex>
                <Flex sx={{
                    transform: 'scale(0.95)',
                    transition: 'box-shadow 0.5s, transform 0.5s',
                    '&:hover': {
                        transform: 'scale(1.3)',
                    },
                }} as={'button'}>
                    <Icon icon={'mdi:logout'} width={30} height={30} color='#fff' onClick={handleLogout} />
                </Flex>
            </Flex>
            <Flex
                flexDir={'column'}
                mt={6}
                mb={2}
                height={'300px'}
                overflowY={'scroll'}

            >
                {/* <Text fontSize={20} color={'#fff'} mb={2}>Products You Paid : </Text> */}
                {resp !== null ? resp.product.map((product, index) => (
                    <Flex
                        borderColor={'silver'}
                        borderBottomWidth={index === resp.product.length - 1 ? '0px' : '0.1px'}
                        bgColor={product.has_update !== '' && !resp.product.some(prd => prd.name === product.has_update) ? '#302769' : '#241d4e'}
                        borderTopRadius={index === 0 ? 8 : 0}
                        borderBottomRadius={index === resp.product.length - 1 ? 8 : 0}
                        height={'40px'}
                        p={2}
                        color={'#fff'}

                        justifyContent={'start'}

                    >
                        <Text
                            fontSize={18}
                            _hover={{ color: 'silver' }}
                            fontWeight={'bold'}
                            onClick={() => window.open(product.link, '_blank')}
                            as={'button'}
                        >
                            - {product.name}
                        </Text>
                        {product.has_update !== '' && !resp.product.some(prd => prd.name === product.has_update) && (
                            <Flex
                                ml={2}
                                sx={{
                                    transform: 'scale(0.95)',
                                    transition: 'box-shadow 0.5s, transform 0.5s',
                                    '&:hover': {
                                        transform: 'scale(1.3)',
                                    },
                                }}
                                as="button"
                                onClick={() => naviagteNewProduct(product.has_update)}
                                color="red"
                                alignItems="center"
                            >
                                <Icon icon="dashicons:update-alt" width={30} height={30} />
                            </Flex>
                        )}

                    </Flex>
                )) : (
                    <Flex
                        borderColor={'silver'}
                        borderBottomWidth={'0px'}
                        bgColor={'#241d4e'}
                        borderTopRadius={8}
                        borderBottomRadius={8}
                        p={2}
                        color={'#fff'}
                        justifyContent={'start'}
                    >
                        <Skeleton width={'260px'} height={'30px'} startColor='#351f4a' endColor='#1d2850' />
                    </Flex>
                )}


            </Flex>

            {resp !== null ? (
                <Input
                    placeholder='Serial'
                    bgColor={'#302769'}
                    borderColor={'#302769'}
                    width={{ base: '100%', md: '70%' }}
                    alignSelf={'center'}
                    mt={5}
                    color={'#fff'}
                    ref={serialRef}
                    defaultValue={resp.serial}
                />
            ) : (
                <Skeleton
                    startColor='#351f4a' endColor='#1d2850'
                    width={{ base: '100%', md: '70%' }}
                    height={'30px'}
                    alignSelf={'center'}
                    mt={5}
                />
            )}
            <Button
                bgGradient={bk1}
                mt={5}
                width={'160px'}
                color={'#fff'}
                alignSelf={'center'}
                _hover={{ opacity: 0.7 }}
                borderBottomRightRadius={16}
                borderTopLeftRadius={16}
                onClick={handleUpdateSerial}
            >
                {loadingSerial ? <Icon icon={'eos-icons:bubble-loading'} width={30} height={30} /> : 'Save'}
            </Button>
        </Flex>
    )
}
