import React, { useState, useRef, useEffect } from 'react'
import { Flex, Box, Input, Button, Text, RadioGroup, Radio, Menu, MenuButton, MenuList, MenuItem, Alert, AlertIcon } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { bk1 } from '../assets/colors';
export default function AddBill() {
    const access_token = Cookies.get('access_token');
    const navigate = useNavigate();
    const [resp, setResp] = useState('')
    const nameRef = useRef('');
    const amountRef = useRef('');
    const noteRef = useRef('');


    const [value, setValue] = useState('1');
    const [valueType, setValueType] = useState('1');
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const allOptions = ['StcPay Nader', 'Wail', 'Binance', 'PayPal', 'Ecommerce', 'Cash', 'STC'];
    const handleChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        // Filter options based on input
        const filteredOptions = allOptions.filter(option =>
            option.toLowerCase().includes(value.toLowerCase())
        );
        setSuggestions(filteredOptions);
    };
    const [paymentMethod, setPaymentMethod] = useState('Select an option')
    function getDate() {
        var now = new Date();
        var saudiTime = new Date(now.getTime() + (3 * 60 * 60 * 1000));

        // Convert the date to a string in the local format
        return saudiTime.toISOString();
    }
    const handleButton = () => {
        const type = valueType === '1' ? 'Incomes' : 'Expenses'
        const data = {
            "name": nameRef.current.value,
            "paid_method": paymentMethod,
            "type": type,
            "amount": amountRef.current.value,
            "note": noteRef.current.value,
            "date": getDate()
        }
        try {
            const req = fetch('https://api.zyll.shop/add-bill/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => response.ok ? navigate('/bills') : setResp(response.json()))
        } catch (error) {
            setResp(error.message)
        }

    }

    return (
        <Flex
            mt='20px'
            width={{ base: '90%', md: '40%' }}
            borderRadius='8px'
            bgColor='#1B1639'
            color={'#fff'}
            justifyContent='start'
            alignItems='center'
            flexDirection='column'
        >
            <Flex
                height={{ base: '160px', md: '100px' }}
                m={2}
                borderRadius='8px'
                width={'90%'}
                flexDirection='column'
                alignItems='center'
            >
                <Input id='name' ref={nameRef} mt='10px' defaultValue='Program' width='90%' />
                <RadioGroup name="form-name" colorScheme='purple' display='flex' m={2} width='90%' justifyContent='space-between' onChange={setValue} value={value} flexDirection={{ base: 'column', md: 'row' }}>
                    <Radio value='1' onClick={() => document.getElementById('name').value = 'Program'}>Program</Radio>
                    <Radio value='2' onClick={() => document.getElementById('name').value = 'Tool'}>Tool</Radio>
                    <Radio value='3' onClick={() => document.getElementById('name').value = 'RDP'}>RDP</Radio>
                    <Radio value='4' onClick={() => document.getElementById('name').value = 'Proxies'}>Proxies</Radio>
                </RadioGroup>
            </Flex>
            <Flex
                height={{ base: '230px', md: '230px' }}
                borderRadius='8px'
                width={'90%'}
                flexDirection='column'
                alignItems='center'
            >
                <Menu>
                    <MenuButton
                        as={Button}
                        color='white'
                        bgGradient={bk1}
                        _hover={{ opacity: 0.7 }}
                        id='id_method'
                        width='50%'
                    >
                        {paymentMethod}
                    </MenuButton>
                    <MenuList
                        bgColor={'purple.900'}
                        borderColor={'purple.900'}
                    >
                        {allOptions.map((option, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => setPaymentMethod(option)}
                                bgColor='purple.900'
                                borderRadius={4}
                                fontWeight={'bold'}
                                _hover={{ backgroundColor: 'silver', color: 'purple.900' }}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
                <Input ref={amountRef} mt='10px' type='number' placeholder='0.0' width='90%' />
                <RadioGroup name="type-name" colorScheme='black' display='flex' m='10px' width='90%' justifyContent='space-between' onChange={setValueType} value={valueType}>
                    <Radio value='1' colorScheme='green'>Incomes</Radio>
                    <Radio value='2' colorScheme='red'>Expenses</Radio>
                </RadioGroup>
                <Input ref={noteRef} mt='10px' placeholder='Note' width='90%' />
                <Button
                    onClick={handleButton}
                    color='white'
                    bgGradient={bk1}
                    _hover={{ opacity: 0.7 }}
                    mt='10px'
                    mb='10px'
                    id='id_method'
                    width='50%'
                >Add Bill</Button>


            </Flex>
        </Flex>
    )
}