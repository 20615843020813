import React, { useState, useEffect } from 'react'
import { Flex, Text, Button, Image, Grid, GridItem, Box, useToast } from '@chakra-ui/react'
import { Icon } from '@iconify/react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bk1 } from '../assets/colors'
import Cookies from 'js-cookie';

export default function Products() {
    const navigate = useNavigate();
    const { category } = useParams();
    const { products } = useSelector((state) => state.product)
    const { isLogged } = useSelector((state) => state.auth);
    const [filterdProducts, setFilterdProducts] = useState(null);
    const access_token = Cookies.get('access_token');
    const toast = useToast();
    useEffect(() => {
        if (category === undefined && filterdProducts === null && products !== null) {
            setFilterdProducts(products)
        }
        else if (category !== null && products !== null && filterdProducts === null) {
            setFilterdProducts(products.filter((prd) => category === prd.category))
        }
    }, [products, category, filterdProducts])

    const handleCart = (id) => {
        if (isLogged) {
            const data = {
                id: id,
            }
            try {
                fetch('https://api.zyll.shop/add-cart/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`
                    },
                    body: JSON.stringify(data)
                })
                    .then((response) => response.ok && navigate('/cart'))


            } catch (error) {
                toast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',
                })
            }
        }
    }
    function capitalize(string) {
        if (!string) return string; // If the string is empty, undefined, or null, return it as is
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const location = useLocation();
    const type_page = capitalize(location.pathname.split('/')[1]);
    useEffect(() => {
        fetch('https://api.zyll.shop/visit/products/')
    }, [])
    return (
        <Flex
            width={'90%'}
            justifyContent={'center'}
            height={'500px'}
            alignContent={'center'}
        >
            {filterdProducts !== null ? (
                <Grid
                    p={4}
                    width={{ base: 'fit', md: '100%' }}
                    templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }}
                    columnGap={{ base: 1, md: 2, lg: 4, xl: 8 }}
                    rowGap={{ base: 10, md: 8, lg: 8, xl: 8 }}

                >
                    {products !== null && products.filter((prd) => prd.type === type_page).filter((prd) => prd.hide === false).map((item) => (
                        <GridItem
                            width={{ base: '300px', md: '280px' }}
                            //bgGradient={bk1}
                            bgColor={'#1B1639'}
                            color={'white'}

                            borderRadius={8}
                            borderTopRadius={2}
                            sx={{
                                transition: 'transform 0.3s ease-in-out',
                                '@media(min-width: 48em)': { // This applies to screens wider than 48em (~768px)
                                    '&:hover': {
                                        transform: 'scale(1.1)',
                                        shadow: "0px 0px 30px #5343B7"
                                    },
                                },
                            }}
                            onClick={() => navigate(`/product/${item.id}`)}
                            cursor={'pointer'}
                            height={'230px'}
                        >
                            <Flex flexDir={'column'} borderRadius={8} height={'230px'}>
                                <Image
                                    src={item.image_link}
                                    borderTopRadius={2}
                                    width={'100%'}
                                    height={'170px'}
                                    alignSelf={'center'}
                                />
                                <Flex justifyContent={'space-between'} alignItems={'center'}>
                                    <Flex flexDir={'column'} width={'100%'}>
                                        <Text ml={3} fontSize={18} fontWeight={'bold'} >{item.name.length > 21 ? `${item.name.slice(0, 21)}...` : item.name}</Text>
                                        <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'} mt={1}>
                                            <Text ml={3} fontSize={14} fontWeight={'bold'}>{item.price}$</Text>
                                            {type_page === 'Tools' && <Text mr={3} fontSize={14} fontWeight={'bold'}>{item.count_paid} bought this</Text>}
                                        </Flex>
                                    </Flex>
                                    <Flex
                                        justifyContent={'center'}
                                        borderLeftWidth={0.1}
                                        borderLeftColor={'#d8d8d8'}
                                        height={'60px'}
                                        width={'50px'}
                                        alignItems={'center'}

                                        sx={{
                                            transform: 'scale(0.95)',
                                            transition: 'box-shadow 0.5s, transform 0.5s',
                                            '&:hover': {
                                                transform: 'scale(1)',
                                                backgroundColor: '#d8d8d8',
                                                boxShadow: '5px 20px 30px rgba(0, 0, 0, 0.3)',
                                                borderEndEndRadius: 8,
                                                color: 'purple.500',
                                            },
                                        }}
                                    >
                                        <Icon
                                            icon={'material-symbols:add-shopping-cart'}
                                            width={30}
                                            height={30}
                                            onClick={() => handleCart(item.id)}
                                        />
                                    </Flex>
                                </Flex>
                                {/* <Box isTruncated noOfLines={3} dangerouslySetInnerHTML={{ __html: item.description }}></Box> */}
                            </Flex>
                        </GridItem>
                    ))}
                </Grid>
            ) : <Icon icon={'svg-spinners:pulse-rings-3'} width={'200px'} height={'200px'} style={{ marginTop: 200 }} />}

        </Flex >
    )
}
