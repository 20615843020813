import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Input, Button, Menu, MenuList, MenuButton, MenuItem, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bk1 } from '../assets/colors';
import { Icon } from '@iconify/react';
import Cookies from 'js-cookie';
export default function Users() {
    const navigate = useNavigate();
    const [resp, setResp] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const [textFilter, setTextFilter] = useState('Filter');
    useEffect(() => {
        if (resp === null) {
            fetch('https://api.zyll.shop/users/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('access_token')}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setResp(data.reverse())
                    setAllUsers(data)
                })
        }
    }, [resp])
    const handleSearch = (value) => {
        let filterUsers = allUsers.filter(word => word.username.toLowerCase().includes(value.toLowerCase()));
        setResp(filterUsers);
    }
    const handleFilter = (text) => {
        setTextFilter(text)
        if (text === 'Last User') {
            const filterUsers = allUsers.reverse();
            setResp(filterUsers)
        }
        else if (text === 'First User') {
            const filterUsers = allUsers.sort((a, b) => a.id - b.id);
            setResp(filterUsers)
        }
        else if (text === 'High Bought') {
            const filterUsers = allUsers.sort((a, b) => b.count_products - a.count_products);
            setResp(filterUsers)
        }
        else if (text === 'Low Bought') {
            const filterUsers = allUsers.sort((a, b) => b.count_products - a.count_products).reverse();
            setResp(filterUsers)
        }
    }
    return (
        <Flex
            bgColor={'#1B1639'}
            //bgGradient={bk1}
            width={{ base: '90%', md: '40%' }}
            height={'500px'}
            borderRadius={8}
            flexDir={'column'}
        >
            <Flex width={'80%'} alignSelf={'center'} justifyContent={'space-between'}>
                <Input
                    placeholder='Search'
                    width={'70%'}
                    alignSelf={'center'}
                    onChange={(e) => handleSearch(e.target.value)}
                    mt={5}
                    mb={5}
                    bgColor={'purple.900'}
                    borderColor={'purple.900'}
                    color={'#fff'}
                />
                <Menu>
                    <MenuButton
                        as={Button}
                        rightIcon={<Icon icon={'mage:filter'} width={20} height={20} />}
                        mt={5}
                        width={'26%'}
                        mb={5}
                        colorScheme='purple'
                        variant={'outline'}
                    >
                        Filter
                    </MenuButton>
                    <MenuList bgColor={'purple.900'} color={'#fff'} borderWidth={'bold'}>
                        <MenuItem
                            bgColor={'purple.900'}
                            p={2}
                            _hover={{ bgColor: 'silver', color: 'purple.900', borderRadius: '5px' }}
                            fontWeight={'bold'}
                            onClick={() => handleFilter('Last User')}
                        >Last User</MenuItem>
                        <MenuItem
                            bgColor={'purple.900'}
                            fontWeight={'bold'}
                            p={2}
                            _hover={{ bgColor: 'silver', color: 'purple.900', borderRadius: '5px' }}
                            onClick={() => handleFilter('First User')}
                        >First User</MenuItem>
                        <MenuItem
                            bgColor={'purple.900'}
                            fontWeight={'bold'}
                            p={2}
                            _hover={{ bgColor: 'silver', color: 'purple.900', borderRadius: '5px' }}
                            onClick={() => handleFilter('High Bought')}
                        >High Bought</MenuItem>
                        <MenuItem
                            bgColor={'purple.900'}
                            fontWeight={'bold'}
                            p={2}
                            _hover={{ bgColor: 'silver', color: 'purple.900', borderRadius: '5px' }}
                            onClick={() => handleFilter('Low Bought')}
                        >Low Bought</MenuItem>
                    </MenuList>
                </Menu>

            </Flex>
            <Flex
                overflowY={'scroll'}
                width={'80%'}
                bgColor={'purple.900'}
                height={'400px'}
                alignSelf={'center'}
                borderRadius={2}
                borderTopLeftRadius={20}
                borderBottomRightRadius={20}
                flexDir={'column'}
                alignItems={'center'}
                pt={4}
            >
                {resp !== null ? resp.map((item, index) => (
                    <Flex
                        bgColor={'#1B1639'}
                        width={'90%'}
                        height={'50px'}
                        m={1}
                        borderRadius={2}
                        borderTopLeftRadius={20}
                        borderBottomRightRadius={20}
                        color={'#fff'}
                        alignItems={'center'}
                        p={2}
                        _hover={{ opacity: 0.7 }}
                        cursor={'pointer'}
                        justifyContent={'space-between'}
                        onClick={() => navigate(`/set-profile/${item.username}`)}
                    >
                        <Flex>
                            <Text mr={1}>{item.id} - </Text>
                            <Text
                                fontSize={18}
                                fontWeight={'bold'}
                            >{item.username}</Text>
                        </Flex>
                        <Flex alignItems={'center'}>
                            <Icon icon={'fluent-mdl2:product-variant'} width={20} height={20} />
                            <Icon icon={`tabler:number-${item.count_products.toString()}-small`} width={30} height={30} />

                        </Flex>
                    </Flex>
                )) : (
                    <>
                        <Waiting />
                        <Waiting />
                        <Waiting />
                        <Waiting />
                        <Waiting />
                        <Waiting />
                    </>
                )}
            </Flex>
        </Flex>
    )
}
function Waiting() {
    return (
        <Skeleton
            startColor='#351f4a' endColor='#1d2850'
            width={'90%'}
            height={'50px'}
            m={1}
            borderRadius={2}
            borderTopLeftRadius={20}
            borderBottomRightRadius={20}
        />
    )
}