import React, { useState, useEffect, useRef } from 'react'
import { Box, Flex, Text, Input, Button, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { bk1 } from '../assets/colors';
import { Icon } from '@iconify/react';
import { changePassword } from '../store/authSlice';

export default function ChangePassword() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const access_token = Cookies.get("access_token");
    const { isLoading, isPasswordChanged, respChangePassword } = useSelector((state) => state.auth)
    const [done, setDone] = useState(null)
    const oldPasswordRef = useRef('');
    const newPasswordRef = useRef('');
    const reNewPasswordRef = useRef('');
    const handleLogout = () => {
        Cookies.remove('access_token');
        navigate('/login');
        window.location.reload();
    }
    useEffect(() => {
        console.log('inside')
        console.log(respChangePassword)
        console.log(isPasswordChanged)
        if (isPasswordChanged === false) {
            if (respChangePassword !== null) {
                toast({
                    title: respChangePassword !== null ? respChangePassword : 'Something went wrong, Make sure of the old password',
                    status: 'error',
                    duration: 1500,
                    isClosable: true,
                    position: 'top',
                })
            }
        }
        else if (isPasswordChanged === true) {
            toast({
                title: 'Password changed successfully',
                status: 'success',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            handleLogout();

        }


    }, [])

    const handleChangePassword = () => {
        const oldPassword = oldPasswordRef.current.value;
        const newPassword = newPasswordRef.current.value;
        const reNewPassword = reNewPasswordRef.current.value;
        if (oldPassword === '' || newPassword === '' || reNewPassword === '') {
            toast({
                title: 'Please fill all fields',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            return;
        }
        if (newPassword !== reNewPassword) {
            toast({
                title: 'Passwords do not match',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            return;
        }
        const data = {
            oldPassword,
            newPassword
        }
        dispatch(changePassword(data))
        setDone(true)
    }

    return (
        <Flex
            bgColor={'#1B1639'}
            height={'300px'}
            width={{ base: '80%', md: '30%' }}
            alignItems={'center'}
            borderRadius={8}
            flexDir={'column'}
            mt={10}
            color={'#fff'}
        >
            <Text
                fontSize={22}
                fontWeight={'bold'}
                color={'#fff'}
                m={5}
            >Change Password</Text>
            <Input
                placeholder='Old Password'
                width={'80%'}
                ref={oldPasswordRef}
                m={1}
                type='password'
            />
            <Input
                placeholder='New Password'
                width={'80%'}
                ref={newPasswordRef}
                m={1}
                type='password'
            />
            <Input
                placeholder='Re-New Password'
                width={'80%'}
                ref={reNewPasswordRef}
                m={1}
                type='password'
            />
            <Button
                colorScheme="purple"
                variant="solid"
                _hover={{ opacity: 0.7 }}
                bgGradient={bk1}
                color={'#fff'}
                _active={{ opacity: 0.7 }}
                fontWeight={'bold'}
                mt={4}
                width={'80%'}
                onClick={handleChangePassword}
            >{isLoading ? <Icon icon={'eos-icons:bubble-loading'} width={30} height={30} /> : 'Submit'}</Button>
        </Flex>
    )
}
