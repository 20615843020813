import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Input, Image, Button, useToast, Skeleton } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bk1, bk3 } from '../assets/colors';
import Cookies from 'js-cookie';
import arc from '../assets/arc-logo.png'
import paypal from '../assets/paypal2.png'
import stc from '../assets/Stc-logo.png'
import stcpay from '../assets/stc-pay.png'

export default function Cart() {
    const navigate = useNavigate();
    const [resp, setResp] = useState(null)
    const toast = useToast()
    const [total, setTotal] = useState(0.0);
    const access_token = Cookies.get("access_token");
    const [loadingButton, setLoadingButton] = useState(false)
    const [IDS, setIDS] = useState([]);
    useEffect(() => {
        if (resp === null) {
            fetch('https://api.zyll.shop/cart/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then((response) => response.json())
                .then((data) => setResp(data))
        }
    }, [resp])
    useEffect(() => {
        if (total === 0 && resp !== null) {
            let newTotal = 0;
            resp.product.forEach((item) => {
                newTotal += Number(item.price);
            });
            setTotal(newTotal);
        }
    }, [resp])
    const handleUpdate = (id) => {

        const data = {
            id: id,
        }
        try {
            fetch('https://api.zyll.shop/update-cart/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => response.ok && window.location.reload())


        } catch (error) {
            toast({
                title: error.message,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
        }
    }
    const handleCheckout = () => {
        resp.product && resp.product.map((item) => IDS.push(item.id))

        navigate('/checkout', { state: { IDS, total } });
    }
    return (
        <Flex
            flexDir={{ base: 'column', md: 'row' }}
            width={{ base: '98%', md: '80%' }}
            height={'fit'}
            borderRadius={8}
            justifyContent={{ base: 'center', md: 'space-between' }}

        >
            <Flex
                width={{ base: '90%', md: '65%' }}
                height={{ base: '260px', md: '400px' }}
                bgColor={'#1B1639'}
                borderRadius={8}
                m={4}
                mr={{ base: 4, md: 1 }}
                color={'#fff'}
                flexDir={'column'}
            >
                <Text
                    fontSize={22}
                    fontWeight={'bold'}
                    mt={2}
                    ml={4}
                >
                    Cart
                </Text>
                <Box alignSelf={'center'} height={'1px'} bgColor={'silver'} opacity={0.5} width={'96%'} mt={1}></Box>
                <Flex overflowY={'scroll'} flexDir={'column'}>
                    {resp !== null ? resp.product.map((item) => (
                        <Flex

                            height={'140px'}
                            width={'96%'}
                            bgColor={'purple.900'}
                            alignSelf={'center'}
                            mt={2}
                            borderRadius={8}

                        >
                            <Image
                                src={item.image_link}
                                width={170}
                                height={130}
                                m={1}
                                borderRadius={8}
                            />
                            <Flex flexDir={'column'} width={'100%'}>
                                <Flex justifyContent={'space-between'} width={'100%'}>
                                    <Text
                                        fontSize={{ base: 18, md: 22 }}
                                        fontWeight={'bold'}
                                        m={1}
                                    >{item.name}</Text>
                                    <Text
                                        fontSize={20}
                                        fontWeight={'bold'}
                                        color={'red'}
                                        mr={2}
                                        cursor={'pointer'}
                                        sx={{
                                            transition: 'box-shadow 0.5s, transform 0.5s',
                                            '&:hover': {
                                                transform: 'scale(1.1)',
                                            },
                                        }}
                                        onClick={() => handleUpdate(item.id)}
                                    >X</Text>
                                </Flex>
                                <Text
                                    fontSize={18}
                                    fontWeight={'bold'}
                                    m={1}
                                >{item.price}$</Text>
                            </Flex>

                        </Flex>
                    )) : (
                        <Skeleton
                            startColor='#351f4a'
                            endColor='#1d2850'
                            width={'96%'}
                            height={'140px'}
                            mt={2}
                            borderRadius={8}
                            alignSelf={'center'}
                        />

                    )}
                </Flex>

            </Flex>


            <Flex
                width={{ base: '90%', md: '35%' }}
                height={{ base: '260px', md: '400px' }}
                bgColor={'#1B1639'}
                borderRadius={8}
                m={4}
                ml={{ base: 4, md: 1 }}
                color={'#fff'}
                flexDir={'column'}
                overflowY={'scroll'}

            >
                <Flex width={'100%'} justifyContent={'space-between'}>
                    <Text
                        fontSize={22}
                        fontWeight={'bold'}
                        mt={2}
                        ml={4}
                    >
                        TOTAL
                    </Text>
                    <Text
                        fontSize={22}
                        fontWeight={'bold'}
                        mt={2}
                        mr={4}
                    >
                        {total}$
                    </Text>
                </Flex>

                <Box alignSelf={'center'} height={'1px'} bgColor={'silver'} opacity={0.5} width={'96%'} mt={1}></Box>
                <Button
                    m={3}
                    mt={5}
                    color={'#fff'}
                    bgGradient={bk1}
                    fontSize={22}
                    _hover={{ opacity: 0.7 }}
                    borderTopLeftRadius={20}
                    borderBottomRightRadius={20}
                    borderTopRightRadius={2}
                    borderBottomLeftRadius={2}
                    onClick={handleCheckout}
                >
                    CHECKOUT
                </Button>
                <Flex
                    mt='16px'
                    flexDirection="column"
                    width='90%'
                    alignSelf='center'
                    alignItems={'center'}
                >
                    <Text fontFamily='Inika' fontSize='16px' fontWeight='bold' textAlign={'center'}>If your payment method one of these please text me on <a style={style} href='https://www.instagram.com/zyll'>Instagram</a> or <a style={style} href='https://t.me/zyllhamood'>Telegram</a></Text>
                    <Flex mt='8px' justifyContent='space-around' width='100%'>
                        <Image src={paypal} alt="BTC" boxSize="40px" />
                        <Image src={stcpay} alt="BTC" boxSize="40px" />
                        <Image src={arc} alt="BTC" boxSize="40px" />
                        <Image src={stc} alt="BTC" boxSize="40px" />
                    </Flex>
                    {/* <Button mt='10px' width='100%' bg="#313131" color='white'>PayPal - STCPAY - Alrajhi - STC SAWA</Button> */}

                </Flex>
            </Flex>
        </Flex>
    )
}
const style = { color: '#4540ca', textDecoration: 'underline', cursor: 'pointer' }