import React, { useState, useRef } from 'react'
import { Box, Flex, Text, Input, Button, Textarea } from '@chakra-ui/react';
import { bk1 } from '../../assets/colors';
export default function SortingList() {
    const [sortedList, setSortedList] = useState([]);
    const [shuffleList, setShuffleList] = useState([]);
    const [result, setResult] = useState('');
    const [list, setList] = useState([]);

    const handleShuffle = () => {
        setResult('');
        setResult(shuffleList.join('\n'))

    }
    const handleSorting = () => {
        setResult('');
        setResult(sortedList.join('\n'))

    }
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            const lines = text.split('\n');
            setList(lines.join('\n'));
        }
        reader.readAsText(file);
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch('https://api.zyll.shop/sort-list/', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            setSortedList(result.sortedList);

        } catch (error) {
            console.error('Error:', error);
        }
        try {
            const response = await fetch('https://api.zyll.shop/shuffle-list/', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            setShuffleList(result.new_list);

        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <>
            <Flex width='100%' justifyContent='center' color={'#fff'}>
                <Flex
                    width={{ base: '90%', md: '40%' }}
                    height='540px'
                    mt='20px'
                    flexDir='column'
                    borderRadius='8px'
                    justifyContent='start'
                    alignItems='center'
                    flexDirection='column'
                >
                    <Input
                        type="file"
                        onChange={handleFileUpload}
                        p={1}
                        accept=".txt*"
                        m='10px' width='96%'
                    />
                    <Textarea m='10px' width='96%' height='310px' value={list}></Textarea>
                    <Box display='flex' justifyContent={'space-between'} width={'96%'}>
                        <Button
                            onClick={handleShuffle}
                            width='45%'
                            color={'#fff'}
                            bgGradient={bk1}
                            _hover={{ opacity: 0.7 }}
                        >Shuffle</Button>
                        <Button
                            onClick={handleSorting}
                            width='45%'
                            color={'#fff'}
                            bgGradient={bk1}
                            _hover={{ opacity: 0.7 }}
                        >Sorting</Button>
                    </Box>

                    {result !== "" && <Textarea m='10px' width='96%' height='310px' value={result}></Textarea>}


                </Flex>
            </Flex>
        </>
    )
}