import React, { useState, useRef } from 'react'
import { Box, Flex, Text, Textarea, Input, Button } from '@chakra-ui/react';
import { bk1 } from '../../assets/colors';
export default function AddWord() {
    const beforeRef = useRef('');
    const afterRef = useRef('');
    const [result, setResult] = useState('');
    const [list, setList] = useState([]);
    const [newList, setNewList] = useState([]);
    const handleButton = () => {
        setResult('');
        setResult(newList.join('\n'));

    }
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            const lines = text.split('\n');
            setList(lines.join('\n'));
        }
        reader.readAsText(file);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('after', afterRef.current.value);
        formData.append('before', beforeRef.current.value);

        try {
            const response = await fetch('https://api.zyll.shop/add-word/', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            setNewList(result.new_list);

        } catch (error) {
            console.error('Error:', error);
        }

    };
    return (
        <>
            <Flex width='100%' justifyContent='center' color={'#fff'}>
                <Flex
                    width={{ base: '90%', md: '40%' }}
                    height='600px'
                    mt='20px'
                    flexDir='column'
                    borderRadius='8px'
                >
                    <Flex mt='10px' justifyContent='space-between' width='90%' alignSelf='center'>
                        <Input ref={beforeRef} placeholder='Before' width='40%' />
                        <Text mt='8px'>Word</Text>
                        <Input ref={afterRef} placeholder='After' width='40%' />
                    </Flex>
                    <Input
                        type="file"
                        onChange={handleFileUpload}
                        p={1}
                        accept=".txt*"
                        m='10px' width='90%'
                        alignSelf={'center'}
                    />
                    <Flex m='10px' justifyContent='center' width='90%' alignSelf='center' flexDirection='column'>
                        <Textarea value={list} m='10px' alignSelf='center' height='100px' resize='none'></Textarea>
                        <Button
                            onClick={handleButton}
                            mt='10px'
                            color={'#fff'}
                            bgGradient={bk1}
                            _hover={{ opacity: 0.7 }}
                        >Start</Button>
                        {result !== "" && <Textarea value={result} mt='20px' alignSelf='center' height='260px' resize='none'></Textarea>}
                    </Flex>



                </Flex>
            </Flex>
        </>
    )
}