import React, { useState } from 'react'
import { Box, Flex, Input, Button, Textarea } from '@chakra-ui/react';
import { bk1 } from '../../assets/colors';
export default function SplitList() {
    const [list1, setList1] = useState([]);
    const [list2, setList2] = useState([]);
    const [list, setList] = useState([]);
    const [result1, setResult1] = useState('');
    const [result2, setResult2] = useState('');
    const [typeSplit, setTypeSplit] = useState('');
    const handleButton = () => {
        setResult1('');
        setResult2('');
        setResult1(list1.join('\n'))
        setResult2(list2.join('\n'))

    }
    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const text = e.target.result;
            const lines = text.split('\n');
            setList(lines.join('\n'));
        }
        reader.readAsText(file);
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', typeSplit);

        try {
            const response = await fetch('https://api.zyll.shop/split-list/', {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            setList1(result.list1);
            setList2(result.list2);

        } catch (error) {
            console.error('Error:', error);
        }

    }

    return (
        <>
            <Flex width='100%' justifyContent='center' color={'#fff'}>
                <Flex
                    width={{ base: '90%', md: '40%' }}
                    height='540px'
                    mt='20px'
                    flexDir='column'
                    borderRadius='8px'
                >
                    <Input id='split' placeholder=':' value={typeSplit} onChange={(e) => setTypeSplit(e.target.value)} width='50%' alignSelf='center' mt='10px' />
                    <Input
                        type="file"
                        onChange={handleFileUpload}
                        p={1}
                        accept=".txt*"
                        m='10px' width='50%'
                        alignSelf={'center'}
                        disabled={typeSplit !== "" ? false : true}
                    />
                    <Textarea id='list_id' width='50%' alignSelf='center' mt='10px' height='200px' resize='none' value={list}></Textarea>

                    <Button
                        width='50%'
                        alignSelf='center'
                        color={'#fff'}
                        mt='10px'
                        onClick={handleButton}
                        bgGradient={bk1}
                        _hover={{ opacity: 0.7 }}
                    >Split</Button>
                    <Box display='flex' justifyContent='space-evenly'>
                        {result1 !== "" && <Textarea width='40%' resize='none' alignSelf='center' mt='10px' height='200px' value={result1}></Textarea>}
                        {result2 !== "" && <Textarea width='40%' resize='none' alignSelf='center' mt='10px' height='200px' value={result2}></Textarea>}
                    </Box>
                </Flex>

            </Flex>
        </>
    )
}