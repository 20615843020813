import React, { useState, useEffect } from 'react'
import { Flex, Text, Input, Checkbox, Radio, RadioGroup, Stack, Button, Box, Skeleton } from '@chakra-ui/react';
import { useLocation, useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
export default function Payment() {
    const navigate = useNavigate();
    const { paymentID } = useParams();
    const [crypto, setCrypto] = useState('');
    const [address, setAddress] = useState('');
    const [amount, setAmount] = useState('');
    const [name, setName] = useState('');
    const [orderID, setOrderID] = useState('');
    const [payemntStatus, setPaymentStatus] = useState('');
    const access_token = Cookies.get('access_token');
    const headers = {
        'Content-Type': 'application/json',
    }

    useEffect(() => {
        const checkPaymentStatus = () => {
            if (payemntStatus === 'Expired') return;
            if (access_token !== undefined) {
                headers['Authorization'] = `Bearer ${access_token}`;
            }
            try {
                fetch(`https://api.zyll.shop/payment-status/${paymentID}/`, {
                    method: 'GET',
                    headers: headers
                })
                    .then((response) => response.json())
                    .then((resp) => {
                        if (resp) {
                            try {
                                if (resp.password !== undefined) {
                                    setInterval(2000000)
                                    Swal.fire({
                                        icon: 'success',
                                        title: `${resp.username}:${resp.password}`,
                                        text: 'Payment Confirmed, Change Password After Login',
                                        confirmButtonColor: '#a5dc86'
                                    }).then(() => navigate('/login'))
                                }
                            } catch (error) {

                            }
                            try {
                                if (resp.error === 'Payment expired') {
                                    setPaymentStatus('Expired')
                                }
                            } catch (error) {

                            }

                            if (resp.payment_status === 'confirming') {
                                setPaymentStatus('Confirming')
                                if (access_token !== undefined && access_token !== '') {
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Payment Confirmed',
                                        text: 'Click ok and you will be redirect to your profile',
                                        confirmButtonColor: '#a5dc86',
                                    })
                                        .then(() => navigate('/profile'))
                                }
                                else {
                                    if (resp.username === 'AnonymousUser') {
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Payment Confirmed',
                                            text: 'Please contact us on instagram @zyll and make sure to send url confirmation',
                                            confirmButtonColor: '#a5dc86'
                                        })
                                    }
                                    else {
                                        //navigate(`/register/${paymentID}`)

                                    }

                                }

                            }
                            else {
                                setAddress(resp.address)
                                setName(resp.name)
                                setOrderID(resp.order_id)
                                setAmount(resp.amount)
                                setCrypto(resp.crypto)
                                setPaymentStatus('Pending ...')
                            }


                        }
                    });
            } catch (error) {

            }

        };

        checkPaymentStatus();
        const interval = setInterval(checkPaymentStatus, 100000); // 10000 ms = 10 seconds

        return () => clearInterval(interval);
    }, [access_token, paymentID]); // Add dependencies here


    return (
        <Flex
            width={{ base: '90%', md: '40%' }}
            flexDir={'column'}
            bgColor={'#1B1639'}
            mt={10}
            borderRadius={8}
            alignItems={'center'}
            color={'#fff'}
            pb={4}
        >

            <Flex mt={3} flexDir={'column'} alignItems={'center'}>
                {address !== '' ? <Text fontSize={22}>{name}</Text> : <Skeleton width={'200px'} height={'30px'} />}
                {address !== '' ? <Text fontSize={22}>{crypto}</Text> : <Skeleton mt={3} width={'100px'} height={'25px'} />}
                {address !== '' ? <Text fontSize={22} textAlign='center' width={{ base: '70%', md: '100%' }}>{address}</Text> : <Skeleton mt={3} width={'400px'} height={'30px'} />}
                <Box mt={5}>{address !== '' ? address !== undefined && <QRCode value={address} size={200} mt={5} /> : <Skeleton width={'200px'} height={'200px'} />}</Box>
                {amount !== '' ? <Text mt={3} fontSize={22}>Amount: {amount}</Text> : <Skeleton mt={3} width={'200px'} height={'30px'} />}
                {payemntStatus !== '' ? <Text mt={3} fontSize={22} color={payemntStatus === 'confirming' ? 'green' : 'orange'}>Status: {payemntStatus}</Text> : <Skeleton mt={3} width={'160px'} height={'30px'} />}
            </Flex>
        </Flex>
    )
}
