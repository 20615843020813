import React, { useEffect, useState, useRef } from 'react'
import { Flex, Text, Button, Image, Box, chakra, useDisclosure, Skeleton } from '@chakra-ui/react'
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
} from '@chakra-ui/react';

import { Modal, ModalFooter, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody, FormControl, FormLabel, Input } from '@chakra-ui/react';

import { motion, AnimatePresence, animate } from 'framer-motion';
import shape1 from '../assets/20.png'
import shape2 from '../assets/17.png'
import shape3 from '../assets/21.png'
import shape4 from '../assets/19.png'
import { Icon } from '@iconify/react'
import { useNavigate } from 'react-router-dom'
import { bk1 } from '../assets/colors'
import { InView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
export default function Home() {
    const [hasAnimated, setHasAnimated] = useState(false);
    const { email } = useSelector((state) => state.auth)
    const { products } = useSelector((state) => state.product)
    useEffect(() => {
        // Trigger animation only once when data is loaded and it hasn't animated yet
        if (products !== null && !hasAnimated) {
            setHasAnimated(true);
        }
    }, [products, email, hasAnimated]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const MotionBox = chakra(motion.div);
    const containerVariants = {
        hidden: {
            y: -20,
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 1.5, // Adjust this value to control the speed
                ease: "easeInOut"
            }
        },
    };
    // useEffect(() => {
    //     fetch('https://api.zyll.shop/visit/home/')
    // }, [])
    return (
        <Flex minH={'300vh'} width={'80%'} alignItems={'center'} alignSelf={'center'} color={'#fff'} flexDir={'column'} mt={{ base: '-40px', md: '0' }}>
            {/* Tools */}
            <AnimatePresence>
                {hasAnimated ? (
                    <MotionBox
                        variants={containerVariants}
                        initial="hidden"
                        animate={"visible"}
                        exit="hidden"
                    >
                        <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'}>

                            <Flex width={'50%'} display={{ base: 'none', md: 'block' }}>
                                <Image src={shape1} mr={20} pt={50} alignSelf={'center'} />
                            </Flex>
                            <Flex flexDir={'column'} justifyContent={'start'} width={{ base: '100%', md: '50%' }}>
                                <TextPro txt={'Tools'} path={'/tools'} />
                                <TextHeader txt={'Uncover the Hidden Potential of Social Media'} />
                                <TextBody txt={'Our innovative tools empower you to gather valuable public data and extract insights from leading social media platforms like Instagram and Twitter. Identify trends, analyze user behavior, and gain a deeper understanding of your target audience. Optimize your social media strategy with data-driven decision making, and leverage our tools to enhance your marketing and sales efforts and increase your followers.'} />
                            </Flex>
                        </Flex>
                    </MotionBox>
                ) : (
                    <Flex h={'70vh'} justifyContent={'center'} alignItems={'center'} color={'#1B1639'}>
                        <Icon icon={'svg-spinners:pulse-rings-3'} width={'200px'} height={'200px'} />
                    </Flex>
                )}
            </AnimatePresence>

            {/* Services */}
            <InView triggerOnce={true}>
                {({ ref, inView }) => (
                    hasAnimated && (
                        <MotionBox
                            variants={containerVariants}
                            initial="hidden"
                            ref={ref}
                            animate={inView ? 'visible' : 'hidden'}
                            exit="hidden"


                        >
                            <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'}>


                                <Flex flexDir={'column'} justifyContent={'start'} width={{ base: '100%', md: '50%' }}>
                                    <Box onClick={onOpen}><TextPro txt={'Services'} /></Box>
                                    <Modal isOpen={isOpen} onClose={onClose} >
                                        <ModalOverlay />
                                        <ModalContent bgColor={'purple.900'} color={'#fff'} width={{ base: '80%', md: 'fit' }}>
                                            <ModalHeader><TextHeader txt={'Contact Info'} /></ModalHeader>
                                            <ModalCloseButton />
                                            <ModalBody>
                                                <Flex alignItems={'center'}>
                                                    <TextBody txt={'Instagram : '} />
                                                    <TextHref txt={'zyll'} path={'https://www.instagram.com/zyll'} />
                                                </Flex>
                                                <Flex alignItems={'center'}>
                                                    <TextBody txt={'Telegram : '} />
                                                    <TextHref txt={'zyllhamood'} path={'https://t.me/zyllhamood'} />
                                                </Flex>
                                                <Flex alignItems={'center'}>
                                                    <TextBody txt={'Gmail : '} />
                                                    <TextHref txt={'zyllhamood'} path={'mailto:zyllhamood@gmail.com'} />
                                                </Flex>
                                            </ModalBody>

                                            <ModalFooter>
                                                <Button colorScheme='blue' mr={3} onClick={onClose}>
                                                    Close
                                                </Button>

                                            </ModalFooter>
                                        </ModalContent>
                                    </Modal>

                                    <TextHeader1 txt={'Programming Tools for Social Media'} />
                                    <TextBody1 txt={'We develop custom tools for platforms like Instagram and Twitter, including account crackers, checkers, and data scrapers. These tools leverage API endpoints to gather and filter user information, providing efficient solutions for managing social media accounts and extracting valuable data.'} />
                                    <TextHover title={'Programming Tools for Social Media'} txt={'We develop custom tools for platforms like Instagram and Twitter, including account crackers, checkers, and data scrapers. These tools leverage API endpoints to gather and filter user information, providing efficient solutions for managing social media accounts and extracting valuable data.'} />

                                    <TextHeader1 txt={'Making Telegram Bots'} />
                                    <TextBody1 txt={'We create Telegram bots tailored to your specific needs. Whether you want a bot to download videos from various platforms or to automate other tasks, we can build it for you. (Note: If the bot requires a list of proxies, it should be developed as a tool rather than a bot.)'} />
                                    <TextHover title={'Making Telegram Bots'} txt={'We create Telegram bots tailored to your specific needs. Whether you want a bot to download videos from various platforms or to automate other tasks, we can build it for you. (Note: If the bot requires a list of proxies, it should be developed as a tool rather than a bot.)'} />

                                    <TextHeader1 txt={'Building Full-Stack Website'} />
                                    <TextBody1 txt={'We offer complete web development solutions, creating visually appealing and highly functional websites. Our services include developing e-commerce platforms, interactive blogs, and custom content management systems, ensuring your digital presence is robust and effective.'} />
                                    <TextHover title={'Building Full-Stack Website'} txt={'We offer complete web development solutions, creating visually appealing and highly functional websites. Our services include developing e-commerce platforms, interactive blogs, and custom content management systems, ensuring your digital presence is robust and effective.'} />

                                    <TextHeader1 txt={'Converting Source Code (Python, C#)'} />
                                    <TextBody1 txt={'We specialize in converting code between C# and Python, ensuring seamless transitions and maintaining functionality across platforms.'} />
                                    <TextHover title={'Converting Source Code (Python, C#)'} txt={'We specialize in converting code between C# and Python, ensuring seamless transitions and maintaining functionality across platforms.'} />

                                    <TextHeader1 txt={'Creating Custom .svb Configs'} />
                                    <TextBody1 txt={'We craft .svb configurations for tools like OpenBullet and SilverBullet, enhancing checker functionalities for website authentication, data scraping, or credential testing. These custom configs are tailored to meet specific site requirements, improving efficiency and accuracy.'} />
                                    <TextHover title={'Creating Custom .svb Configs'} txt={'We craft .svb configurations for tools like OpenBullet and SilverBullet, enhancing checker functionalities for website authentication, data scraping, or credential testing. These custom configs are tailored to meet specific site requirements, improving efficiency and accuracy.'} />

                                    <TextHeader1 txt={'Source Extraction with DnSpy'} />
                                    <TextBody1 txt={'We excel in extracting source code from programs built in C# or VB.net using DnSpy. This service is essential for analyzing and understanding .NET applications, enabling code review and modifications.'} />
                                    <TextHover title={'Source Extraction with DnSpy'} txt={'We excel in extracting source code from programs built in C# or VB.net using DnSpy. This service is essential for analyzing and understanding .NET applications, enabling code review and modifications.'} />

                                    <TextHeader1 txt={'Encryption And Protection'} />
                                    <TextBody1 txt={'We provide encryption and protection services for C# and VB.net applications, safeguarding them against unauthorized access and ensuring their integrity. This service is crucial for maintaining the security and reliability of your software.'} />
                                    <TextHover title={'Encryption And Protection'} txt={'We provide encryption and protection services for C# and VB.net applications, safeguarding them against unauthorized access and ensuring their integrity. This service is crucial for maintaining the security and reliability of your software.'} />



                                </Flex>
                                <Flex width={'50%'} display={{ base: 'none', md: 'block' }}>
                                    <Image src={shape3} pt={10} alignSelf={'center'} />
                                </Flex>
                            </Flex>
                        </MotionBox>
                    )
                )}
            </InView>

            {/* Products */}
            <InView triggerOnce={true}>
                {({ ref, inView }) => (
                    hasAnimated && (
                        <MotionBox
                            variants={containerVariants}
                            initial="hidden"
                            ref={ref}
                            animate={inView ? 'visible' : 'hidden'}
                            exit="hidden"


                        >
                            <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'}>

                                <Flex width={'50%'} display={{ base: 'none', md: 'block' }}>
                                    <Image src={shape2} pt={10} alignSelf={'center'} />
                                </Flex>
                                <Flex flexDir={'column'} justifyContent={'start'} width={{ base: '100%', md: '50%' }}>
                                    <TextPro txt={'Products'} path={'/products'} />
                                    <TextHeader txt={'Maximize Your Social Media Efficiency'} />
                                    <TextBody txt={'Our premium RDP services are perfect for running Instagram checkers, guessers, and bots, providing a secure environment for automation.'} />
                                    <TextHeader txt={'Enhance Your Development'} />
                                    <TextBody txt={'Leverage our APIs for Instagram, Twitter, Gmail, Yahoo, e.g. Create powerful tools and bots, including checkers and guessers, to boost your strategy.'} />


                                    <TextHeader txt={'Ensure Reliable Connectivity'} />
                                    <TextBody txt={'Our high-quality proxies provide stable and secure connections for managing your online activities.'} />
                                </Flex>
                            </Flex>
                        </MotionBox>
                    )
                )}
            </InView>


            {/* Free */}
            <InView triggerOnce={true}>
                {({ ref, inView }) => (
                    hasAnimated && (
                        <MotionBox
                            variants={containerVariants}
                            initial="hidden"
                            ref={ref}
                            animate={inView ? 'visible' : 'hidden'}
                            exit="hidden"


                        >
                            <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'}>


                                <Flex flexDir={'column'} justifyContent={'start'} width={{ base: '100%', md: '50%' }}>
                                    <TextPro txt={'Free Tools'} />

                                    {/* <Flex
                                    flexDir={'column'}
                                    alignItems={'center'}
                                    width={{ base: '90%', md: '40%' }}
                                >
                                    <ButtonHeader txt={'Build Request'} />
                                    <ButtonHeader txt={'Split List'} />
                                    <ButtonHeader txt={'Add Word To List'} />
                                    <ButtonHeader txt={'Delete Repeated'} />
                                    <ButtonHeader txt={'Sorting List'} />
                                    <ButtonHeader txt={'Get Proxies'} />
                                    <ButtonHeader txt={'Combo Maker'} />
                                    <ButtonHeader txt={'List Maker'} />
                                    <ButtonHeader txt={'Save Mine'} />
                                </Flex> */}
                                    <TextHeader1 path={'/build-request'} txt={'Build Request'} />
                                    <TextHover path={'/build-request'} title={'Build Request'} txt={'Helps developers create API requests from URL, headers, and data. Supports GET and POST methods, generating code in three ways: Python (requests), C# (HttpWebRequest), and C# (HttpRequest Leaf.xNet)'} />
                                    <TextBody1 txt={'Helps developers create API requests from URL, headers, and data. Supports GET and POST methods, generating code in three ways: Python (requests), C# (HttpWebRequest), and C# (HttpRequest Leaf.xNet)'} />

                                    <TextHeader1 path={'/split'} txt={'Split List'} />
                                    <TextHover path={'/split'} title={'Split List'} txt={'Insert an input delimiter (e.g., "|"), load the list, and split it into two boxes. The first box contains items before the delimiter, and the second box contains items after.'} />
                                    <TextBody1 txt={'Insert an input delimiter (e.g., "|"), load the list, and split it into two boxes. The first box contains items before the delimiter, and the second box contains items after.'} />

                                    <TextHeader1 path={'/add-word'} txt={'Add Word To List'} />
                                    <TextHover path={'/add-word'} title={'Add Word To List'} txt={'Load a list, then add words before or after each item using two input fields. The tool generates a new list with the added words.'} />
                                    <TextBody1 txt={'Load a list, then add words before or after each item using two input fields. The tool generates a new list with the added words.'} />

                                    <TextHeader1 path={'/delete-repeated'} txt={'Delete Repeated'} />
                                    <TextHover path={'/delete-repeated'} title={'Delete Repeated'} txt={'Load a list and remove repeated items, providing a clean, unique list.'} />
                                    <TextBody1 txt={'Load a list and remove repeated items, providing a clean, unique list.'} />

                                    <TextHeader1 path={'/sorting-list'} txt={'Sorting List'} />
                                    <TextHover path={'/sorting-list'} title={'Sorting List'} txt={'Load a list and sort the items in ascending order.'} />
                                    <TextBody1 txt={'Load a list and sort the items in ascending order.'} />

                                    <TextHeader1 path={'/get-proxies'} txt={'Get Proxies'} />
                                    <TextHover path={'/get-proxies'} title={'Get Proxies'} txt={'Choose the type of proxy (HTTP/S, SOCKS4, SOCKS5) and click to get free proxies.'} />
                                    <TextBody1 txt={'Choose the type of proxy (HTTP/S, SOCKS4, SOCKS5) and click to get free proxies.'} />


                                    <Box onClick={() => window.open('https://mega.nz/file/3rQ1iZaB#HZ-EfRkHmWkL4KVtznEK0Ot2oHmwdza4zkvVqjg4nOE', '_blank')}>
                                        <TextHeader1 txt={'Combo Maker'} />
                                        <TextHover title={'Combo Maker'} txt={'Create user:pass combos with various options for customizing the output format.'} />

                                    </Box>
                                    <TextBody1 txt={'Create user:pass combos with various options for customizing the output format.'} />


                                    <Box onClick={() => window.open('https://mega.nz/file/Lmx3zKAJ#BOsBj_GVIv45RP1wtXK5w1u4RMM0WNudzUtmETM3sDM', '_blank')}>
                                        <TextHeader1 txt={'List Maker'} />
                                        <TextHover title={'List Maker'} txt={'Create lists with a specified length of letters.'} />

                                    </Box>
                                    <TextBody1 txt={'Create lists with a specified length of letters.'} />


                                    <Box onClick={() => window.open('https://t.me/save_mine_bot', '_blank')}>
                                        <TextHeader1 txt={'Save Mine'} />
                                        <TextHover title={'Save Mine'} txt={'A Telegram bot that downloads videos from links. Supports Instagram, Twitter, YouTube, TikTok, Facebook, Threads, and Pinterest.'} />

                                    </Box>
                                    <TextBody1 txt={'A Telegram bot that downloads videos from links. Supports Instagram, Twitter, YouTube, TikTok, Facebook, Threads, and Pinterest.'} />


                                </Flex>
                                <Flex width={'50%'} display={{ base: 'none', md: 'block' }}>
                                    <Image src={shape4} pt={10} alignSelf={'center'} />
                                </Flex>
                            </Flex>
                        </MotionBox>
                    )
                )}
            </InView>

        </Flex>
    )
}

const TextPro = ({ txt, path }) => {
    const navigate = useNavigate()
    const hoverStyles = txt !== 'Free Tools' ? {
        '&:hover': {
            transform: 'scale(1.1)',
        },
    } : {};
    return (
        <Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Text
                as={txt === 'Free Tools' ? 'text' : 'button'}
                fontSize={{ base: 70, md: 70 }}

                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                fontFamily={'JainiPurva'}
                alignSelf={'start'}
                mt={16}
                sx={{
                    transform: 'scale(0.95)',
                    transition: 'box-shadow 0.5s, transform 0.5s',
                    ...hoverStyles, // Spread hover styles conditionally
                }}
                onClick={() => path !== undefined && navigate(`${path}`)}
            >{txt}</Text>
            <Box mt={16} display={{ base: 'block', md: 'none' }} onClick={() => navigate(path)}>
                {txt !== 'Free Tools' && <Icon icon={'majesticons:open'} width={40} height={40} />}
            </Box>
        </Flex>

    )
}
const TextHeader = ({ txt }) => {
    return (
        <Text
            fontSize={24}
            width={'100%'}
            mt={5}
            fontFamily={'PoetsenOne'}
        >{txt}</Text>
    )
}
const TextBody = ({ txt }) => {
    return (
        <Box
            opacity={0.7}
            p={2}
            borderRadius={8}
        >
            <Text
                fontFamily={'Freeman'}
                fontSize={24}
                width={'100%'}
                mt={1}
                color={'white'}
            >
                {txt}
            </Text>
        </Box>

    )
}

const TextHeader1 = ({ txt, path }) => {
    const navigate = useNavigate();
    return (
        <Text
            fontSize={24}
            display={{ base: 'block', md: 'none' }}
            width={'100%'}
            mt={5}
            fontFamily={'PoetsenOne'}
            onClick={() => path !== undefined && navigate(`${path}`)}
        >{txt}</Text>
    )
}
const TextBody1 = ({ txt }) => {
    return (
        <Box
            opacity={0.7}
            p={2}
            borderRadius={8}
            display={{ base: 'block', md: 'none' }}
        >
            <Text
                fontFamily={'Freeman'}
                fontSize={24}
                width={'100%'}
                mt={1}
                color={'white'}
            >
                {txt}
            </Text>
        </Box>

    )
}

const TextHover = ({ title, txt, path }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate()
    return (
        <Box display={{ base: 'none', md: 'block' }} onClick={() => path !== undefined && navigate(path)}>
            <Popover isOpen={isOpen} onClose={onClose}>
                <PopoverTrigger>
                    <Text
                        cursor="pointer"
                        onMouseEnter={onOpen}
                        onMouseLeave={onClose}
                        fontSize={24}
                        display={'inline-block'}
                        mt={5}
                        fontFamily={'PoetsenOne'}
                    >
                        {title}
                    </Text>
                </PopoverTrigger>
                <PopoverContent
                    bgColor={'purple.900'}
                    borderWidth={0}
                    fontSize={20}
                >
                    <PopoverBody

                    >{txt}</PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    )
}
const TextHref = ({ txt, path }) => {
    return (
        <Flex onClick={() => window.open(path, '_blank')} alignItems={'center'} _hover={{ opacity: 1.5 }} as={'Button'}>
            <Text
                color={'blue'}
                _hover={{ textDecoration: 'underline' }}
                fontSize={24}
                mt={1}
            >
                {txt}
            </Text>
            <Box mt={-4} ml={1}>
                <Icon icon={'majesticons:open'} width={16} height={16} />
            </Box>
        </Flex>
    )
}
const ButtonHeader = ({ txt, path }) => {
    const navigate = useNavigate()
    return (
        <Text
            bgGradient={bk1}
            p={2}
            borderRadius={40}
            fontFamily={'PoetsenOne'}
            width={'100%'}
            as={'Button'}
            height={'46px'}
            sx={{
                transition: 'box-shadow 0.5s, transform 0.5s',
                '&:hover': {
                    transform: 'scale(1.1)',
                },
            }}
        >
            {txt}
        </Text>
    )
}