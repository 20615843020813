import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Input, useToast, Checkbox, Button, Menu, MenuButton, MenuList, MenuItem, Textarea, TableContainer, Td, Tr, Thead, Table, Box, Th, Tbody, } from '@chakra-ui/react';
import { bk1 } from '../../assets/colors';
import { Icon } from '@iconify/react';
export default function Marketing() {
    const [allUsers, setAllUsers] = useState([])
    const [newList, setNewList] = useState([])
    const [oldList, setOldList] = useState([])
    const [locations, setLocations] = useState([])
    const [count, setCount] = useState(0)
    const wordRef = useRef('');
    const [txtButton, setTxtButton] = useState('Start')

    const [hideComments, setHideComments] = useState(false);

    const handleButton = () => {
        setAllUsers('');
        setTxtButton(<Icon icon={'svg-spinners:wind-toy'} height={'40px'} width={'40px'} />)
        const word = wordRef.current.value;

        const data_send = { "word": word };

        fetch('https://api.zyll.shop/get-users-marketing/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Ensures the server knows to expect JSON
            },
            body: JSON.stringify(data_send)
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.users);
                setNewList(data.users);
                setOldList(data.users);
                const uniqueItems = Array.from(new Set(data.users.map(item => item.split(':')[3])));
                uniqueItems.push('Filter')
                setLocations(uniqueItems)

                setAllUsers(data.users.join('\n')); // Use data.users directly
                setCount(data.users.length);
                console.log('users');
                console.log(allUsers); // This may still log the old value
                console.log(newList); // This may still log the old value
                setTxtButton('Start')
            })
            .catch((error) => {
                console.error('Error:', error);
                setTxtButton('Start')
            });



    }
    useEffect(() => {
        console.log('Updated users:', newList);
        console.log('Formatted users:', allUsers);
    }, [newList, allUsers, count]);



    const [textFilter, setTextFilter] = useState('Filter');
    const handleFilter = (text) => {
        setNewList(oldList)
        setTextFilter(text)

        if (text === 'Filter') {
            setNewList(oldList)
            return;
        }
        const filterUsers = oldList.filter((item) => item.split(':')[3].toLowerCase() === text.toLowerCase())
        setNewList(filterUsers)
    }

    return (
        <Flex
            bgColor={'#1B1639'}
            width={'70%'}
            height={'500px'}
            mt={10}
            borderRadius={8}
            alignItems={'center'}
            flexDir={'column'}
            color={'#fff'}

        >
            <Flex
                width={'90%'}
                justifyContent={'space-between'}
                alignItems={'center'}
                mt={6}
            >
                <Input
                    placeholder='Enter Your Business Name'
                    width={'80%'}

                    ref={wordRef}

                />
                <Checkbox

                    colorScheme='purple'
                    onChange={() => setHideComments(!hideComments)}
                ></Checkbox>
                <Menu>
                    <MenuButton
                        as={Button}


                        width={'10%'}

                        colorScheme='purple'
                        variant={'outline'}
                    >
                        Filter
                    </MenuButton>
                    <MenuList bgColor={'purple.900'} color={'#fff'} borderWidth={'bold'}>
                        {locations.map((item) => (
                            <MenuItem
                                bgColor={'purple.900'}
                                p={2}
                                _hover={{ bgColor: 'silver', color: 'purple.900', borderRadius: '5px' }}
                                fontWeight={'bold'}
                                onClick={() => handleFilter(item)}
                            >{item}</MenuItem>
                        ))}
                    </MenuList>
                </Menu>

            </Flex>


            <Button
                bgGradient={bk1}
                color={'#fff'}
                mt={4}
                width={'90%'}
                _hover={{ opacity: 0.7 }}
                onClick={handleButton}
            >
                {txtButton}
            </Button>
            {/* <Textarea
                placeholder='Result'
                width={'90%'}
                mt={4}
                height={'60%'}
                value={allUsers}

            ></Textarea> */}
            <Flex
                width={'100%'}
                justifyContent={'center'}
                color={'#fff'}
                maxH={'320px'}

            >
                <TableContainer width={'82%'} overflowY={'scroll'}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Username</Th>
                                <Th>Interest</Th>

                                {hideComments === false && <Th>Comment</Th>}
                                {hideComments === true && <Th>Location</Th>}

                            </Tr>
                        </Thead>

                        <Tbody>
                            {newList !== null ? newList.map((item) => (
                                <Tr>
                                    <Td>{item.split(':')[0]}</Td>
                                    <Td>{item.split(':')[1]}</Td>
                                    {hideComments === false && <Td>{item.split(':')[2]}</Td>}
                                    {hideComments === true && <Td>{item.split(':')[3]}</Td>}


                                </Tr>
                            )) : ''}


                        </Tbody>

                    </Table>
                </TableContainer>
            </Flex>
            <Flex
                width={'90%'}
            >
                <Text
                    mt={4}
                    alignSelf={'start'}

                >Count : {count}</Text>
            </Flex>
        </Flex>
    )
}
