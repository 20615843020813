import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Input, Checkbox, Skeleton, TableContainer, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

export default function Orders() {
    const [orders, setOrders] = useState(null);
    const { products } = useSelector((state) => state.product)
    const [status, setStatus] = useState('confirming');
    const access_token = Cookies.get('access_token')
    const [allOrders, setAllOrders] = useState(null)
    function getProductNames(productIds) {
        const idToNameMap = new Map();
        products !== null && products.forEach(product => {
            idToNameMap.set(product.id, product.name);
        });

        // Mapping the product IDs to their respective names
        return productIds.map(id => idToNameMap.get(id) || 'Unknown Product').join(', ');
    }
    const handleStatus = () => {
        if (status === 'confirming') {
            setStatus('waiting')
        }
        else {
            setStatus('confirming')
        }

    }
    useEffect(() => {
        if (orders === null && products !== null) {
            fetch('https://api.zyll.shop/orders/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            }).then((response) => response.json())
                .then((data) => {
                    setOrders(data);
                    setAllOrders(data);
                })
        }




    }, [orders, products])
    function formatDateString(dateString) {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}:${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    }
    const handleChange = (e) => {
        const value = e.target.value.toLowerCase();
        let filterUsers = allOrders.filter(word => word.username.toLowerCase().includes(value) || word.payment_id.toLowerCase().includes(value));
        setOrders(filterUsers);
    }
    return (
        <>
            <Flex
                width={{ base: '90%', md: '80%' }}
                bgColor={'#1B1639'}
                height={'80px'}
                mt={10}
                borderRadius={8}
                justifyContent={'center'}
                color={'#fff'}
            >
                <Input

                    placeholder='Search'
                    bgColor={'purple.900'}
                    width={'90%'}
                    borderColor={'purple.900'}
                    mt={4}
                    onChange={handleChange}
                />
            </Flex>
            <Flex
                width={{ base: '90%', md: '80%' }}
                bgColor={'#1B1639'}
                color={'#fff'}
                height={'500px'}
                mt={4}
                borderRadius={8}
                flexDir={'column'}
                alignItems={'center'}
                overflowY={'scroll'}
            >
                <Checkbox
                    defaultChecked
                    onChange={handleStatus}
                    alignSelf={'start'}
                    m={4}
                    ml={{ base: 8, md: 16 }}
                    onClick={() => handleStatus}
                    colorScheme='purple'
                >Confirming</Checkbox>
                <TableContainer color={'#fff'} width={'90%'} overflowY={'scroll'}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th><Icon icon={'mdi:user'} width={20} height={20} /></Th>
                                <Th><Icon icon={'icon-park-outline:bar-code'} width={20} height={20} /></Th>
                                <Th><Icon icon={'icon-park-outline:ad-product'} width={20} height={20} /></Th>
                                <Th><Icon icon={'clarity:date-line'} width={20} height={20} /></Th>

                            </Tr>
                        </Thead>

                        <Tbody>
                            {orders !== null && orders.filter((prd) => prd.status === status).reverse().map((item) => (
                                <Tr>
                                    <Td>{item.id}</Td>
                                    <Td>{item.username}</Td>
                                    <Td>{item.payment_id}</Td>
                                    <Td>{products ? <Text>{getProductNames(item.products)}</Text> : <><Skeleton mt={1} width={'200px'} height={'20px'} /></>}</Td>
                                    <Td>{formatDateString(item.created_at)}</Td>


                                </Tr>
                            ))}


                        </Tbody>

                    </Table>
                </TableContainer>

            </Flex>
        </>
    )
}
function Waiting() {
    return (
        <Flex
            bgColor={'#2d2d2d'}
            width={'96%'}
            height={'120px'}
            m={2}
            p={2}
            flexDir={'column'}
            borderRadius={8}
        >
            <Skeleton height={'30px'} width={'200px'} />
            <Skeleton height={'30px'} width={'200px'} mt={3} />
            <Skeleton height={'30px'} width={'250px'} mt={3} />
            <Skeleton height={'30px'} width={'250px'} mt={3} />


        </Flex>
    )
}

