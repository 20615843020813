import React, { useState, useEffect, useRef } from 'react'
import { Box, Flex, Text, Input, Checkbox, Button, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bk1 } from '../assets/colors';
import Cookies from 'js-cookie';
import { Icon } from '@iconify/react';
export default function SetProfile() {
    const { username } = useParams();
    const serialRef = useRef('');
    const toast = useToast();
    const navigate = useNavigate();
    const [resp, setResp] = useState(null);
    const { products } = useSelector((state) => state.product)
    const [allProducts, setAllProducts] = useState(null)
    const [productsIDS, setProductsIDS] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingButton, setLoadingButton] = useState(false);
    const access_token = Cookies.get('access_token');
    useEffect(() => {
        if (resp === null) {

            fetch(`https://api.zyll.shop/profile/${username}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setResp(data)
                    let ids = []
                    data.product.forEach((product) => ids.push(product.id));
                    setProductsIDS(ids)
                    setIsLoading(false);


                })
        }
    }, [resp, username])
    useEffect(() => {
        if (allProducts === null && products !== null) {
            setAllProducts(products)
        }
    }, [products, allProducts])
    const handleChange = (e, id) => {
        const status = e.target.checked;
        if (status === true) {
            if (!productsIDS.includes(id)) {
                productsIDS.push(id)
            }
        }
        else {
            let index = productsIDS.indexOf(id);
            productsIDS.splice(index, 1);
        }
    }

    const handleSearch = (word) => {
        if (word.length >= 1) {
            let filterProducts = products.filter(product => product.name.toLowerCase().includes(word.toLowerCase()));
            setAllProducts(filterProducts);
        } else {
            setAllProducts(products);
        }
    }
    const handleSubmit = () => {
        setLoadingButton(true);
        const data = {
            products: productsIDS,
            serial: serialRef.current.value,
            username: username
        }
        try {
            fetch('https://api.zyll.shop/update-profile/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                },
                body: JSON.stringify(data)
            })
                .then((response) => {
                    if (response.status === 200) {
                        toast({
                            title: 'Profile updated successfully',
                            status: 'success',
                            duration: 1500,
                            isClosable: true,
                            position: 'top',
                        })
                        navigate('/users')

                    }
                    else {
                        toast({
                            title: response.statusText,
                            status: 'error',
                            duration: 1500,
                            isClosable: true,
                            position: 'top',
                        })
                    }
                })
            setLoadingButton(false);


        } catch (error) {
            toast({
                title: error.message,
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
        }




    }
    return (
        resp !== null ? (
            <Flex
                bgColor={'#1B1639'}
                //bgGradient={bk1}
                width={{ base: '90%', md: '40%' }}
                height={'500px'}
                borderRadius={8}
                flexDir={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                color={'#fff'}
            >
                <Flex justifyContent={'space-between'} alignSelf={'center'} width={'80%'} alignItems={'start'} mt={4}>
                    <Flex alignItems={'center'}>
                        <Icon icon={'mdi:user-outline'} width={24} height={24} />
                        <Text fontSize={20} ml={2} fontWeight={'bold'}>{resp.username}</Text>
                    </Flex>

                    <Flex alignItems={'center'}>
                        <Icon icon={'ic:outline-email'} width={24} height={24} />
                        <Text fontSize={20} ml={2}>{resp.email}</Text>
                    </Flex>
                </Flex>
                <Flex justifyContent={'space-between'} alignSelf={'center'} width={'80%'} alignItems={'start'} mt={4}>
                    <Text
                        textDecoration={'underline'}
                        as={'Button'}
                        onClick={() => navigate(`/change-password-admin/${resp.username}`)}
                    >Change Password</Text>
                </Flex>
                <Input
                    placeholder='Search'
                    width={'80%'}
                    alignSelf={'center'}
                    m={4}
                    bgColor={'purple.900'}
                    borderColor={'purple.900'}
                    color={'#fff'}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                {resp && <Flex
                    bgColor={'purple.900'}
                    //bgGradient={bk1}
                    width={'80%'}
                    height={'260px'}
                    borderRadius={8}
                    flexDir={'column'}
                    overflowY={'scroll'}
                >
                    {allProducts !== null && allProducts.map((item, index) => (

                        !isLoading && resp.product && resp.product.some((prd) => prd.name === item.name) ? (
                            <Flex
                                bgColor={'#1B1639'}
                                width={'100%'}
                                height={'50px'}
                                color={'#fff'}
                                fontWeight={'bold'}
                                pl={5}
                                p={3}
                                borderTopRadius={index === 0 ? 8 : 0}
                                borderColor={'purple.900'}
                                borderWidth={'1px'}
                            >
                                <Checkbox
                                    colorScheme='purple'

                                    defaultChecked

                                    key={item.id}
                                    onChange={(e) => handleChange(e, item.id)}
                                >{item.name}</Checkbox>
                            </Flex>
                        ) : (
                            <Flex
                                bgColor={'purple.900'}
                                width={'100%'}
                                height={'50px'}
                                p={3}
                                color={'#fff'}
                                fontWeight={'bold'}
                                pl={5}
                                borderTopRadius={index === 0 ? 8 : 0}
                            >
                                <Checkbox
                                    colorScheme='purple'
                                    key={item.id}
                                    onChange={(e) => handleChange(e, item.id)}
                                >{item.name}</Checkbox>
                            </Flex>
                        )
                    ))}
                </Flex>}
                <Input
                    placeholder='Serial'
                    width={'80%'}
                    ref={serialRef}
                    defaultValue={resp !== null ? resp.serial : ''}
                    alignSelf={'center'}
                    mt={5}
                    bgColor={'purple.900'}
                    borderColor={'purple.900'}
                    color={'#fff'}

                />
                <Button
                    bgGradient={bk1}
                    m={4}
                    color={'#fff'}
                    width={'200px'}
                    _hover={{ opacity: 0.7 }}
                    onClick={handleSubmit}
                >{loadingButton ? <Icon icon={'eos-icons:bubble-loading'} width={26} height={26} /> : 'Submit'}</Button>
            </Flex>
        ) : (
            <Flex h={'70vh'} justifyContent={'center'} alignItems={'center'} color={'#1B1639'}>
                <Icon icon={'svg-spinners:pulse'} height={200} width={200} />
            </Flex>
        )
    )
}
