import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Button, Input, useToast } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../store/authSlice';
import { Icon } from '@iconify/react';
import { bk1 } from '../assets/colors';
export default function Login() {
    const toast = useToast();
    const usernameRef = useRef('');
    const passwordRef = useRef('');
    const dispatch = useDispatch();
    const { isLogged, wrongLogin, isLoading } = useSelector((state) => state.auth);


    const handleLogin = () => {
        const username = usernameRef.current.value;
        const password = passwordRef.current.value;
        if (username === '' || password === '') {
            toast({
                title: 'Please fill all fields',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            return;
        }
        const data = {
            username,
            password
        }
        dispatch(login(data));
    }
    useEffect(() => {
        if (wrongLogin === true) {
            toast({
                title: 'Wrong username or password',
                status: 'error',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
        }
        else if (isLogged) {
            toast({
                title: 'Logged in successfully',
                status: 'success',
                duration: 1500,
                isClosable: true,
                position: 'top',
            })
            const timer = setTimeout(() => {
                window.location.href = '/profile';
            }, 2000);
            return () => clearTimeout(timer);
        }

    }, [isLogged, wrongLogin])
    useEffect(() => {
        if (isLogged) {
            window.location.href = '/profile';
        }
    }, [isLogged])
    const handleKeyDownPassword = (e) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };
    const handleKeyDownUsername = (e) => {
        if (e.key === 'Enter') {
            passwordRef.current.focus();
        }
    }
    return (
        <Flex
            mt={'100px'}
            flexDir={'column'}
            borderRadius={12}
            bg={'#1B1639'}
            width={{ base: '80%', md: '26%' }}
            height={'340px'}
            boxShadow="0px 0px 60px #5343B7"
            p="6"
            alignItems={'center'}

        >
            <Text
                fontSize={30}
                fontWeight={'bold'}
                fontFamily={'Lastica'}
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                mt={6}
                letterSpacing="10px"
            >
                Login
            </Text>
            <Input
                placeholder='Username'
                m={6}
                width={'90%'}
                color={'#fff'}
                ref={usernameRef}
                onKeyDown={handleKeyDownUsername}
            />
            <Input
                placeholder='Password'
                type='password'
                color={'#fff'}
                width={'90%'}
                ref={passwordRef}
                onKeyDown={handleKeyDownPassword}
            />
            <Button
                variant="solid"
                color={'#fff'}
                bgGradient={bk1}
                fontWeight={'bold'}
                m={5}
                width={'90%'}
                onClick={handleLogin}
                _hover={{ opacity: 0.7 }}
            >{isLoading ? <Icon icon={'eos-icons:bubble-loading'} width={30} height={30} /> : 'Sign in'}</Button>
        </Flex>
    )
}
