import React, { useState, useEffect, useRef } from 'react'
import { Flex, Box, Text, Checkbox, Input, Button, useToast, Skeleton } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bk1 } from '../assets/colors';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
export default function EditProduct() {
    const { id } = useParams();
    const [resp, setResp] = useState(null);
    const access_token = Cookies.get("access_token");
    useEffect(() => {
        if (resp === null) {
            fetch(`https://api.zyll.shop/edit-product/${id}/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`

                },
            })
                .then((response) => response.json())
                .then(data => {
                    setResp(data);
                    setDescription(data.description)
                })
        }
    }, [resp])

    const toast = useToast();
    const navigate = useNavigate();

    const nameRef = useRef(resp !== null && resp.name);
    const priceRef = useRef(0.0);
    const image_linkRef = useRef('');
    const videoRef = useRef('');
    const linkRef = useRef('');
    const placeRef = useRef(0);
    const typeRef = useRef('');
    const statusRef = useRef('');
    const categoryRef = useRef('');
    const hideRef = useRef(false);
    const has_updateRef = useRef('');

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ size: [] }],
            [{ font: [] }],
            [{ align: ["right", "center", "justify"] }],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link", "image"],
            [{ color: ["red", "#785412"] }],
            [{ background: ["red", "#785412"] }]
        ]
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "link",
        "color",
        "image",
        "background",
        "align",
        "size",
        "font"
    ];

    const [description, setDescription] = useState("");
    const handleProcedureContentChange = (content) => {
        setDescription(content);
    }

    const handleEdit = () => {
        const name = nameRef.current.value;
        const price = priceRef.current.value;
        const image_link = image_linkRef.current.value;
        const video = videoRef.current.value;
        const link = linkRef.current.value;
        const place = placeRef.current.value;
        const type = typeRef.current.value;
        const status = statusRef.current.value;
        const category = categoryRef.current.value;
        const hide = hideRef.current.checked;
        const has_update = has_updateRef.current.value;
        const data = {
            name: name,
            price: price,
            image_link: image_link,
            video: video,
            link: link,
            place: place,
            type: type,
            status: status,
            category: category,
            hide: hide,
            has_update: has_update,
            description: description
        }
        fetch(`https://api.zyll.shop/edit-product/${id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        }).then((response) => {
            if (response.status === 200) {
                toast({
                    title: 'Success',
                    description: 'Product edited successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',

                })
                navigate('/products')
            } else {
                toast({
                    title: 'Error',
                    description: response.statusText,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top',
                })
            }
        })
    }

    return (
        <Flex
            bgColor={'#1B1639'}
            width={'80%'}
            height={'fit'}
            borderRadius={8}
            flexDir={'column'}
            p={5}
            color={'#fff'}
        >
            <Input
                placeholder='Name'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={nameRef}
                defaultValue={resp !== null ? resp.name : ''}

            />
            <Input
                placeholder='Price'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={priceRef}
                type='number'
                mt={3}
                defaultValue={resp !== null ? resp.price : ''}
            />
            <Input
                placeholder='Image Link'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={image_linkRef}
                mt={3}
                defaultValue={resp !== null ? resp.image_link : ''}
            />
            <Input
                placeholder='Video'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={videoRef}
                mt={3}
                defaultValue={resp !== null ? resp.video : ''}
            />
            <Flex
                mt='14px'
                borderColor='purple.900'
                bgColor='purple.900'
                height='200px'
                width={'100%'}
                borderRadius={6}
                p={2}
                color={'#fff'}


            >
                <ReactQuill
                    theme="snow"
                    style={{ height: '140px', width: '100%', color: '#fff' }}
                    modules={modules}
                    formats={formats}
                    value={description}
                    onChange={handleProcedureContentChange}

                />
            </Flex>
            <Input
                placeholder='Link Download'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={linkRef}
                mt={3}
                defaultValue={resp !== null ? resp.link : ''}
            />
            <Input
                placeholder='Place'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={placeRef}
                type='number'
                mt={3}
                defaultValue={resp !== null ? resp.place : ''}
            />
            <Input
                placeholder='Type'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={typeRef}
                mt={3}
                defaultValue={resp !== null ? resp.type : ''}
            />
            <Input
                placeholder='Status'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={statusRef}
                mt={3}
                defaultValue={resp !== null ? resp.status : ''}
            />
            <Input
                placeholder='Category'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={categoryRef}
                mt={3}
                defaultValue={resp !== null ? resp.category : ''}
            />
            <Input
                placeholder='Has Update'
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                ref={has_updateRef}
                mt={3}
                defaultValue={resp !== null ? resp.has_update : ''}
            />
            <Checkbox
                ref={hideRef}
                mt={3}
            >Hide</Checkbox>
            <Button
                bgGradient={bk1}
                mt={5}
                color={'#fff'}
                _hover={{ opacity: 0.7 }}
                fontWeight={'bold'}
                onClick={handleEdit}
            >Edit</Button>

        </Flex>
    )
}
