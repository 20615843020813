import React, { useState, useEffect } from 'react'
import { Flex, Box, Text, Input, Skeleton, Button, useToast, Td, Tr, Tfoot, Th, Table, TableContainer, TableCaption, Thead, Tbody } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { bk1 } from '../assets/colors';
import Swal from 'sweetalert2';
export default function ManageProducts() {
    const navigate = useNavigate();
    const { products } = useSelector((state) => state.product)
    const [filterdSearch, setFilterdSearch] = useState(null);
    const access_token = Cookies.get('access_token')

    useEffect(() => {
        if (products !== null && filterdSearch === null) {
            setFilterdSearch(products)
        }
    }, [products, filterdSearch])

    const handleSearch = (word) => {
        if (word.length >= 1) {
            let filterProducts = products.filter(prd => (
                prd.name.toLowerCase().includes(word.toLowerCase()) ||
                prd.status.toLowerCase().includes(word.toLowerCase())
            ));
            setFilterdSearch(filterProducts);
        } else {
            setFilterdSearch(products);
        }


    }

    const handleDelete = (id, name) => {
        Swal.fire({
            title: `Are you sure you want to delete ${name}`,
            showCancelButton: true,
            confirmButtonText: "Confirm Delete",

        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.zyll.shop/delete-product/${id}/`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`
                    },
                })
                window.location.reload();
            }
        });
    }
    return (
        <Flex
            width={{ base: '90%', md: '80%' }}
            height={'500px'}
            bgColor={'#1B1639'}
            borderRadius={8}
            flexDir={'column'}
            alignItems={'center'}
            overflowY={'scroll'}
        >
            <Flex justifyContent={'center'} alignItems={'center'} width={'100%'}>
                <Input
                    placeholder='Search'
                    width={'80%'}
                    alignSelf={'center'}
                    m={5}
                    bgColor={'purple.900'}
                    borderColor={'purple.900'}
                    color={'#fff'}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <Box
                    mr={2}
                    color={'#fff'}
                    sx={{
                        transform: 'scale(0.95)',
                        transition: 'box-shadow 0.5s, transform 0.5s',
                        '&:hover': {
                            transform: 'scale(1.3)',
                        },
                    }}
                    onClick={() => navigate('/add-product')}
                >
                    <Icon icon={'material-symbols:add-ad'} style={{ marginLeft: 5 }} width={40} height={40} />
                </Box>
            </Flex>
            <Flex
                width={'100%'}
                justifyContent={'center'}
                color={'#fff'}

            >
                <TableContainer width={'82%'}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Name</Th>
                                <Th>Hide</Th>
                                <Th>Price</Th>
                                <Th>Status</Th>
                                <Th>Place</Th>
                                <Th>Edit</Th>
                            </Tr>
                        </Thead>

                        <Tbody>
                            {filterdSearch !== null ? filterdSearch.map((item) => (
                                <Tr>
                                    <Td>{item.name}</Td>
                                    <Td>{item.hide === false ? <Icon icon={'mdi:show'} width={20} height={20} /> : <Icon color='red' icon={'mdi:hide'} width={20} height={20} />}</Td>
                                    <Td>{item.price}</Td>
                                    <Td>{item.status}</Td>
                                    <Td>{item.place}</Td>
                                    <Td><Flex>
                                        <Box
                                            sx={{
                                                transform: 'scale(0.95)',
                                                transition: 'box-shadow 0.5s, transform 0.5s',
                                                '&:hover': {
                                                    transform: 'scale(1.3)',
                                                },
                                            }}
                                            onClick={() => navigate(`/edit-product/${item.id}`)}
                                        >
                                            <Icon icon={'mingcute:edit-line'} width={30} height={30} />
                                        </Box>
                                        <Box color={'#a10000'} sx={{
                                            transform: 'scale(0.95)',
                                            transition: 'box-shadow 0.5s, transform 0.5s',
                                            '&:hover': {
                                                transform: 'scale(1.3)',
                                            },
                                        }}
                                            onClick={() => handleDelete(item.id, item.name)}
                                        >
                                            <Icon icon={'ic:outline-delete'} width={30} height={30} />
                                        </Box>
                                    </Flex></Td>
                                </Tr>
                            )) : <Waiting />}


                        </Tbody>

                    </Table>
                </TableContainer>
            </Flex>
        </Flex>
    )
}

function Waiting() {
    return (
        <>
            <Tr>
                <Td><Skeleton width={'230px'} height={'26px'} startColor='#351f4a' endColor='#1d2850' /></Td>
                <Td><Skeleton width={'70px'} height={'26px'} startColor='#351f4a' endColor='#1d2850' /></Td>
                <Td><Skeleton width={'70px'} height={'26px'} startColor='#351f4a' endColor='#1d2850' /></Td>
                <Td><Skeleton width={'110px'} height={'26px'} startColor='#351f4a' endColor='#1d2850' /></Td>
                <Td><Skeleton width={'30px'} height={'26px'} startColor='#351f4a' endColor='#1d2850' /></Td>
                <Td><Skeleton width={'50px'} height={'26px'} startColor='#351f4a' endColor='#1d2850' /></Td>

            </Tr>


        </>
    )
}