import React, { useState, useRef } from 'react'
import { Box, Text, Button, Image, Input, Flex, Menu, MenuButton, MenuList, MenuItem, Textarea } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { bk1 } from '../../assets/colors';
import { Icon } from '@iconify/react';
export default function BuildRequest() {
    const [method, setMethod] = useState('GET');
    const [option, setOption] = useState('Python (requests)');
    const [result, setResult] = useState('');
    const headersRef = useRef('');
    const dataRef = useRef('');
    const url = useRef('');
    const allMethods = ['GET', 'POST'];
    const allOptions = ['Python (requests)', 'C# (Leaf.xNet)', 'C# (HttpWebRequest)'];
    const handleButton = () => {
        const headers = headersRef.current.value;
        const data = dataRef.current.value;
        let head = {};
        let headersArray = headers.split('\n');
        for (let item of headersArray) {
            let txt = item.split(': ');
            if (txt[0] === "Connection" || txt[0] === "Content-Length" || txt[0] === "Accept-Language") {
                continue;
            }
            head[txt[0]] = txt[1];
        }
        let headers_context = "";

        if (option === 'Python (requests)') {
            headers_context = "{";
            for (let key in head) {
                if (head.hasOwnProperty(key)) {
                    let value = head[key];
                    headers_context += `\n\t"${key}": "${value}",`;
                    console.log(key + ': ' + value);
                }
            }
            headers_context += "\n}"
            setResult(`url = "${url.current.value}"\nheaders = ${headers_context}\n${data !== '' && method === 'POST' ? `data = "${data}"` : ''}\nresp = requests.${method === "GET" ? 'get' : 'post'}(url,headers=headers${data.length > 0 && method === "POST" ? ',data=data' : ''}).text`);
        }
        else if (option === 'C# (Leaf.xNet)') {
            for (let key in head) {
                if (head.hasOwnProperty(key)) {
                    let value = head[key];
                    if (key !== "User-Agent") {
                        headers_context += `\n\t\thttpRequest.AddHeader("${key}", "${value}");`;
                    }

                }
            }
            setResult(`string resp = "";\ntry\n{\n${data.length > 0 && method === "POST" ? `\tStringContent data = new StringContent("${data}");\n` : ''}\tusing (HttpRequest Request = new HttpRequest())\n\t{\n\t\tHttpRequest httpRequest = Request;\n\t\thttpRequest.IgnoreProtocolErrors = true;\n\t\thttpRequest.UserAgent = "${head["User-Agent"]}";\n\t\t//httpRequest.Proxy = HttpProxyClient.Parse(proxy);${headers_context}\n\t\tresp = Request.${method === "GET" ? 'Get' : 'Post'}("${url.current.value}"${data.length > 0 && method === "POST" ? ',data' : ''}).ToString();\n\t}\n}\ncatch (Exception ex){}`)
        }
        else if (option === 'C# (HttpWebRequest)') {
            for (let key in head) {
                if (head.hasOwnProperty(key)) {
                    let value = head[key];
                    if (key !== "User-Agent") {
                        if (key === "Accept" || key === "Content-Type" || key === "Referer") {
                            if (key === "Content-Type") {
                                headers_context += `\nHttpWebRequest.ContentType = "${value}";`;
                            }
                            else {
                                headers_context += `\nHttpWebRequest.${key} = "${value}";`;
                            }

                        }
                        else {
                            headers_context += `\nHttpWebRequest.Headers.Add("${key}", "${value}");`;
                        }

                    }

                }
            }
            setResult(`${data.length > 0 && method === "POST" ? `byte[] Bytes = new System.Text.UTF8Encoding().GetBytes("${data}");\n` : ''}HttpWebRequest HttpWebRequest = (HttpWebRequest)WebRequest.Create("${url.current.value}");\nHttpWebRequest.Method = "${method}";${headers_context}\n${data.length > 0 && method === "POST" ? 'HttpWebRequest.ContentLength = Bytes.Length;\nSystem.IO.Stream Stream = HttpWebRequest.GetRequestStream();\nStream.Write(Bytes, 0, Bytes.Length);\nStream.Dispose();\nStream.Close();\n' : ''}HttpWebResponse Response;\ntry\n{\n\tResponse = (HttpWebResponse)HttpWebRequest.GetResponse();\n}\ncatch(WebException ex)\n{\n\tResponse = (HttpWebResponse)ex.Response;\n}\nSystem.IO.StreamReader StreamReader = new System.IO.StreamReader(Response.GetResponseStream());\nstring resp = StreamReader.ReadToEnd().ToString();\nStreamReader.Dispose();\nStreamReader.Close();`)
        }
    }
    return (
        <Flex width='90%' height='600px' mt='20px' borderRadius='8px' flexDir='column' color={'#fff'}>
            <Flex
                justifyContent='space-between'
                alignItems='start'
                width={{ base: '90%', md: '60%' }}
                alignSelf='center'
            >
                <Menu>
                    <MenuButton
                        as={Button}
                        color='white'
                        rightIcon={<Icon icon={'fe:arrow-down'} />}
                        bgGradient={bk1}
                        mt='10px'
                        id='id_method'
                        width='20%'
                        _hover={{ opacity: 0.7 }}
                    >
                        {method}
                    </MenuButton>
                    <MenuList bgColor='#1B1639' m={2} borderColor={'#1B1639'} color={'#fff'}>
                        {allMethods.map((option, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => setMethod(option)}
                                bgColor='#1B1639'
                                p={2}
                                fontWeight={'bold'}
                                borderRadius={4}
                                _hover={{ bgColor: 'silver', color: '#1B1639' }}
                            >
                                {option}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

                <Menu>
                    <MenuButton
                        as={Button}
                        color='white'
                        rightIcon={<Icon icon={'fe:arrow-down'} />}
                        bgGradient={bk1}
                        m='10px'
                        id='id_method'
                        width='50%'
                        _hover={{ opacity: 0.7 }}
                    >
                        {option}
                    </MenuButton>
                    <MenuList borderColor='#1B1639' bgColor='#1B1639' color={'#fff'} m={2}>
                        {allOptions.map((option, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => setOption(option)}
                                bgColor='#1B1639'
                                p={2}
                                fontWeight={'bold'}
                                borderRadius={4}
                                _hover={{ bgColor: 'silver', color: '#1B1639' }}


                            >
                                {option}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
                <Button variant='solid' colorScheme='purple' width='15%' alignSelf='center' onClick={handleButton}>Build</Button>
            </Flex>

            <Input ref={url} placeholder='Url' width={{ base: '90%', md: '60%' }} alignSelf='center' mt='10px' />
            <Textarea ref={headersRef} placeholder='Headers' width={{ base: '90%', md: '60%' }} alignSelf='center' mt='10px' height='200px'></Textarea>
            <Input ref={dataRef} placeholder='Data' width={{ base: '90%', md: '60%' }} alignSelf='center' mt='10px' />
            {result !== "" && <Box width={{ base: '90%', md: '60%' }} height='2px' bgColor='rgb(30,30,30)' mt='10px' alignSelf='center'></Box>}
            {result !== "" && <Textarea placeholder='Request' value={result} width={{ base: '90%', md: '60%' }} alignSelf='center' mt='10px' height='200px' ></Textarea>}
        </Flex>
    )
}