import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Button, Input, useDisclosure, Menu, MenuItem, MenuList, MenuButton, Box, MenuDivider, MenuGroup, Image, VStack, filter } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Icon } from '@iconify/react';
import { bk1 } from '../assets/colors';
import { useSelector, useDispatch } from 'react-redux';
import { setProducts } from '../store/productSlice';


export default function Navbar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredResults, setFilteredResults] = useState([]);
    const searchRef = useRef(null)
    const [isOpenSearch, setIsOpenSearch] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const buttonRef = useRef(null)
    const { isLogged, isAdmin } = useSelector((state) => state.auth);
    const { categories, oldProducts, products } = useSelector((state) => state.product);
    const [textCategory, setTextCategory] = useState('Category');
    const location = useLocation();
    const type_page = location.pathname.split('/')[1];
    const handleCategory = async (category) => {
        if (category === 'Category' || category === 'All') {
            dispatch(setProducts(oldProducts))
        }
        else {
            dispatch(setProducts(oldProducts.filter((prd) => prd.category === category)))
        }
        setTextCategory(category);
        let to = null;
        if (type_page === 'products' || type_page === 'tools') {
            to = type_page
        }
        else {
            if (category === 'All') {
                to = 'tools'
            }
            else {
                to = oldProducts.find((prd) => prd.category === category).type
            }
        }
        console.log(to)
        navigate(`/${to}/${category}`)

    }

    const handleSearch = (event) => {
        setFilteredResults([]);
        const value = event.target.value;
        setSearchTerm(value);

        if (!value.trim()) {
            setFilteredResults([]);
            setIsOpenSearch(false);
            return;
        }

        const filteredResults = products.filter(product =>
            product.name.toLowerCase().includes(value.toLowerCase())
        );

        setFilteredResults(filteredResults);
        setIsOpenSearch(true);
        if (buttonRef.current) {
            if (isOpenSearch === false) {
                buttonRef.current.click();
            }
            else {
                setIsOpenSearch(true);
            }
        }
    };
    const handleSelect = (productId) => {

        navigate(`/product/${productId}`);
        setIsOpenSearch(false);
        setFilteredResults([]);
        searchRef.current.focus = true;
    };
    const handleKeyDownSearch = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Flex
            height={'100px'}
            justifyContent={'space-between'}
            width={'90%'}
            alignSelf={'center'}
            alignItems={'center'}
        >

            <Box display={{ base: 'none', md: 'block' }}
                sx={{
                    transform: 'scale(0.95)',
                    transition: 'box-shadow 0.5s, transform 0.5s',
                    '&:hover': {
                        transform: 'scale(1.3)',
                    },
                }}
            ><TextPro txt={'Zyll'} m={6} font={'Lastica'} path={'/'} /></Box>
            <Flex

                width={{ base: '80%', md: '50%' }}
            >
                <Menu>
                    <MenuButton
                        as={Button}
                        variant='solid'

                        fontWeight={'bold'}
                        borderRightRadius={0}
                        _hover={{ opacity: 0.7 }}
                        bgGradient={bk1}
                        color={'#fff'}
                        _active={{ opacity: 0.7 }}
                    >
                        {textCategory}
                    </MenuButton>
                    <MenuList
                        bgColor='#1B1639' borderRadius={8} borderWidth={0}
                    >
                        {categories && categories.map((category, index) => (
                            <MenuItem
                                key={index}
                                mt={2}
                                bg={category === textCategory ? 'purple.900' : '#1B1639'}
                                color={'#fff'}
                                _hover={{ bg: 'silver', color: '#1d2850' }}
                                fontWeight={'bold'}
                                onClick={() => handleCategory(category)}
                            >
                                {category}
                            </MenuItem>

                        ))}


                    </MenuList>
                </Menu>
                {/* <Button
                    borderRadius={0}
                    borderTopLeftRadius={8}
                    borderBottomLeftRadius={8}
                    bgColor={'#9290C3'}
                    color={'#fff'}
                    width={160}
                    height={9}
                >Categories</Button> */}

                <Menu>
                    <Input
                        borderRadius={0}

                        placeholder='Search'
                        width={{ base: 160, md: 460 }}
                        height='fit'
                        borderColor={'purple.900'}
                        color='#fff'
                        value={searchTerm}
                        onChange={handleSearch}
                        borderRightRadius={8}
                    />
                    <MenuButton

                        isActive={isOpenSearch}
                        as={Button}
                        borderRadius={0}
                        borderTopRightRadius={8}
                        borderBottomRightRadius={8}
                        bgGradient={bk1}
                        color='#fff'
                        width={'50'}
                        height='fit'
                        ref={buttonRef}
                        _hover={{ opacity: 0.7 }}

                        style={{ display: 'none' }}
                    >
                        <Text><Icon icon="material-symbols:search" width="28" height="28" /></Text>
                    </MenuButton>
                    <MenuList
                        bgColor={'purple.900'}
                        borderColor={'purple.900'}
                        width={'fit'}
                        ml={{ base: '10', md: '510px' }}
                        mt={{ base: '20', md: '20' }}
                    >
                        <VStack align="stretch">
                            {filteredResults.filter((item) => item.hide === false).map((product) => (
                                <Flex
                                    key={product.id}
                                    alignItems="center"
                                    p={3}
                                    bgColor="purple.900"
                                    borderRadius="md"
                                    width={'fit'}
                                    _hover={{
                                        backgroundColor: '#9290C3',
                                    }}
                                    as='button'
                                    flexDir={'row'}
                                    onClick={() => {
                                        navigate(`/product/${product.id}`)
                                        window.location.reload();
                                    }}
                                    color={'#fff'}
                                >


                                    <Image src={product.image_link} boxSize="50px" borderRadius={8} mr={4} alt={product.name} />
                                    <Text fontWeight="bold">{product.name}</Text>
                                </Flex>
                            ))}

                        </VStack>


                    </MenuList>
                </Menu>


            </Flex>

            <Flex alignItems={'center'}>
                <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                    <MenuButton
                        as={Button}
                        variant='solid'
                        _hover={{ opacity: 0.7 }}
                        bgGradient={bk1}
                        color={'#fff'}
                        _active={{ opacity: 0.7 }}

                        fontWeight={'bold'}
                    >
                        <Icon key={isOpen}
                            icon={isOpen ? 'line-md:menu-to-close-transition' : 'line-md:close-to-menu-transition'}
                            color="#fff" width="30" height="30" />
                    </MenuButton>
                    <MenuList bgColor='#1B1639' borderRadius={8} borderWidth={0} overflowY={'scroll'} mr={{ base: 5, md: 10 }}>
                        <Box display={{ base: 'block', md: 'none' }}>
                            <MenuItemPro txt={'Home'} path={'/'} icon={'fe:home'} />
                        </Box>
                        {isLogged ? (
                            <>
                                <MenuItemPro txt={'Profile'} path={'/profile'} icon={'iconamoon:profile-bold'} />
                                <MenuItemPro txt={'Cart'} path={'/cart'} icon={'tdesign:cart'} />
                                <MenuItemPro txt={'Change Password'} path={'/change-password'} icon={'fluent:key-reset-20-regular'} />
                            </>
                        ) : <MenuItemPro txt={'Login'} path={'/login'} icon={'ic:round-login'} />}
                        <MenuDivider tutle />
                        <MenuItemPro txt={'Tools'} path={'/tools'} icon={'ic:outline-terminal'} />
                        <MenuItemPro txt={'Products'} path={'/products'} icon={'ant-design:product-outlined'} />

                        {/* <MenuItemPro txt={'Free Tools'} path={'/free'} icon={'grommet-icons:services'} /> */}

                        {isAdmin && (
                            <>
                                <MenuDivider tutle />
                                <MenuGroup title='Admin' color={'#fff'} fontWeight={'bold'}>
                                    <MenuItemPro txt={'Users'} path={'/users'} icon={'clarity:users-solid'} />
                                    <MenuItemPro txt={'Register'} path={'/register'} icon={'mdi:register'} />
                                    <MenuItemPro txt={'Orders'} path={'/orders'} icon={'fluent-mdl2:reservation-orders'} />
                                    <MenuItemPro txt={'Manage Products'} path={'/manage-products'} icon={'fluent-mdl2:product-release'} />
                                    <MenuItemPro txt={'Paid'} path={'/paid'} icon={'tabler:checks'} />
                                    <MenuItemPro txt={'Serials'} path={'/serials'} icon={'mage:bar-code-scan'} />
                                    <MenuItemPro txt={'Logged'} path={'/logged'} icon={'solar:login-3-broken'} />
                                    <MenuItemPro txt={'Bills'} path={'/bills'} icon={'healthicons:bills'} />

                                </MenuGroup>
                            </>
                        )}

                    </MenuList>
                </Menu>
            </Flex>
        </Flex >
    )
}

const TextPro = ({ txt, m, font, path }) => {
    const navigate = useNavigate();
    return (
        <Text
            as={'button'}
            fontSize={20}
            fontWeight={800}
            fontFamily={font}
            color={'#fff'}
            m={m}
            onClick={() => navigate(path)}
        >{txt}</Text>
    )
}
const MenuItemPro = ({ txt, path, icon = null }) => {
    const navigate = useNavigate();
    return (
        <MenuItem
            borderRadius={8}
            mt={2}
            bgColor={'#1B1639'}
            color={'#fff'}
            _hover={{ bg: 'silver', color: '#1d2850' }}
            onClick={() => navigate(path)}
        >
            <Flex justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Text fontWeight={'bold'}>{txt}</Text>
                {icon !== null && <Icon icon={icon} width={26} height={26} />}
            </Flex>
        </MenuItem>
    )
}