import React, { useState, useEffect, useRef } from 'react'
import { Box, Flex, Text, Input, Button, useToast } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { bk1 } from '../assets/colors';
import { Icon } from '@iconify/react';
import { changePassword } from '../store/authSlice';

export default function ChangePasswordAdmin() {
    const { username } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const toast = useToast();
    const access_token = Cookies.get("access_token");
    const [done, setDone] = useState(null)

    const newPasswordRef = useRef('');
    const reNewPasswordRef = useRef('');



    const handleChangePassword = () => {

        const newPassword = newPasswordRef.current.value;

        const data = {
            username,
            "new_password": newPassword
        }
        fetch('https://api.zyll.shop/change-password-admin/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(data)
        }).then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    toast({
                        title: 'Password changed successfully',
                        status: 'success',
                        duration: 1500,
                        isClosable: true,
                        position: 'top',
                    })
                    navigate('/users');
                }
            })
    }

    return (
        <Flex
            bgColor={'#1B1639'}
            height={'200px'}
            width={{ base: '80%', md: '30%' }}
            alignItems={'center'}
            borderRadius={8}
            flexDir={'column'}
            mt={10}
            color={'#fff'}
        >
            <Text
                fontSize={22}
                fontWeight={'bold'}
                color={'#fff'}
                m={5}
            >Change Password : @{username}</Text>

            <Input
                placeholder='New Password'
                width={'80%'}
                ref={newPasswordRef}
                m={1}
                type='password'
            />

            <Button
                colorScheme="purple"
                variant="solid"
                _hover={{ opacity: 0.7 }}
                bgGradient={bk1}
                color={'#fff'}
                _active={{ opacity: 0.7 }}
                fontWeight={'bold'}
                mt={4}
                width={'80%'}
                onClick={handleChangePassword}
            >Sumbit</Button>
        </Flex>
    )
}
