import React, { useState, useEffect } from 'react'
import { Flex, Box, Text, Button, Input, useToast, Checkbox, Radio, RadioGroup, Select, TableContainer, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { bk1 } from '../assets/colors';
import { Icon } from '@iconify/react';
import Swal from 'sweetalert2';
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
export default function Bill() {
    const [value, setValue] = useState('1');
    const [isIncomesChecked, setIsIncomesChecked] = useState(true);
    const [isExpensesChecked, setIsExpensesChecked] = useState(true);
    const access_token = Cookies.get('access_token')
    const [resp, setResp] = useState([]);
    const [oldResp, setOldResp] = useState(null);
    const [allDates, setAllDates] = useState([]);
    const [dateValue, setDateValue] = useState('');
    const [oldData, setOldData] = useState([]);
    const [months, setMonths] = useState([]);
    const [monthText, setMonthText] = useState('Month');
    const navigate = useNavigate()
    useEffect(() => {
        if (oldResp === null) {
            fetch('https://api.zyll.shop/bills/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setOldResp(data)
                    const date = new Date();
                    const currentMonth = date.getMonth() + 1
                    data.map((item) => {
                        const d = formatDateString(item.created_at);
                        const dd = d.split('-')[1]
                        if (!months.includes(dd)) {
                            months.push(dd)
                        }

                        if (Number(dd) === currentMonth) {
                            setResp((prev) => [...prev, item])
                        }
                        else {
                            setOldData((prev) => [...prev, item])
                        }

                    })


                })
        }
    }, [resp])
    const handleMonths = (num) => {
        setMonthText(num)
        const filteredData = oldResp.filter((item) => {
            const d = formatDateString(item.created_at);
            const dd = d.split('-')[1]
            return Number(dd) === Number(num);
        });
        setResp(filteredData);
    }
    const handleCheckIncomes = () => {
        setIsIncomesChecked(!isIncomesChecked);
    };
    const handleCheckExpenses = () => {
        setIsExpensesChecked(!isExpensesChecked);
    };
    function formatDateString(dateString) {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}:${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    }
    function fixPrice(number) {
        return parseFloat(number.toFixed(2));
    }
    const is_dollar = (paid_method) => {
        if (paid_method === 'Binance' || paid_method === 'PayPal' || paid_method === 'Ecommerce') {
            return "$"
        } else {
            return ""
        }
    }
    const incomesSAR = () => {
        let all = 0;
        resp && resp.map((item) => {
            const price = item.amount;
            if (is_dollar(item.paid_method) !== "$" && item.type === "Incomes") {
                all += parseFloat(price);
            }
        })
        return fixPrice(all);
    }
    const incomesUSD = () => {
        let all = 0;
        resp && resp.map((item) => {
            const price = item.amount;
            if (is_dollar(item.paid_method) === "$" && item.type === "Incomes") {
                all += parseFloat(price);
            }
        })
        return fixPrice(all);
    }

    const expensesSAR = () => {
        let all = 0;
        resp && resp.map((item) => {
            const price = item.amount;
            if (is_dollar(item.paid_method) !== "$" && item.type === "Expenses") {
                all -= parseFloat(price);
            }
        })
        return fixPrice(all);
    }

    const expensesUSD = () => {
        let all = 0;
        resp && resp.map((item) => {
            const price = item.amount;
            if (is_dollar(item.paid_method) === "$" && item.type === "Expenses") {
                all -= parseFloat(price);
            }
        })
        return fixPrice(all);
    }
    const balanceSAR = () => {
        let all = 0;
        resp && resp.map((item) => {
            const price = item.amount;
            if (item.type === "Incomes" && is_dollar(item.paid_method) !== "$") {
                all += parseFloat(price);
            }
            else if (item.type === "Expenses" && is_dollar(item.paid_method) !== "$") {
                all -= parseFloat(price);
            }


        })
        return fixPrice(all);
    }
    const balanceUSD = () => {
        let all = 0;
        resp && resp.map((item) => {
            const price = item.amount;
            if (item.type === "Incomes" && is_dollar(item.paid_method) === "$") {
                all += parseFloat(price);
            }
            else if (item.type === "Expenses" && is_dollar(item.paid_method) === "$") {
                all -= parseFloat(price);
            }


        })
        return fixPrice(all);
    }
    const handleSearch = () => {
        const arr = []
        const word = document.getElementById('search').value;

        const by = () => {
            if (value === '1') {
                return 'name'
            }
            else if (value === '2') {
                return 'paid_method'
            }
            else if (value === '3') {
                return 'note'
            }
        }
        resp.map((item) => {
            if (item[by()].includes(word)) {
                if (isIncomesChecked === true && isExpensesChecked === false) {
                    if (item.type === "Incomes") {
                        arr.push(item);
                    }
                }
                else if (isExpensesChecked && isIncomesChecked === false) {
                    if (item.type === "Expenses") {
                        arr.push(item);
                    }
                }
                else {
                    arr.push(item);
                }
            }

        })
        setResp(arr);

    }
    const handleDelete = (id, name) => {
        Swal.fire({
            title: `Are you sure you want to delete ${name}`,
            showCancelButton: true,
            confirmButtonText: "Confirm Delete",

        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`https://api.zyll.shop/delete-bill/${id}/`, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`
                    },
                })
                    .then((response) => window.location.reload())

            }
        });
    }

    return (
        <>
            <Flex
                bgColor={'#1B1639'}
                width={{ base: '90%', md: '40%' }}
                borderRadius={8}
                flexDir={'column'}
                alignItems={'center'}
                color={'#fff'}
            >
                <Button
                    width={'80%'}
                    m={2}
                    mt={6}
                    bgGradient={bk1}
                    color={'#fff'}
                    _hover={{ opacity: 0.7 }}
                    onClick={() => navigate('/add-bill')}
                >
                    Add Bill <Icon icon={'fluent:add-32-filled'} width={20} height={20} style={{ marginLeft: 6 }} />
                </Button>
                <Input
                    placeholder='Search'
                    width={'80%'}
                    bgColor={'purple.900'}
                    borderColor={'purple.900'}
                    mt={2}
                    id='search'
                />
                <RadioGroup
                    name="form-name"
                    colorScheme='purple'
                    display='flex'
                    width='80%'
                    justifyContent='space-between'
                    onChange={setValue} value={value}
                    flexDirection={{ base: 'column', md: 'row' }}
                    mt={4}
                    bgColor={'purple.900'}
                    p={3}
                    borderRadius={8}
                >
                    <Radio value='1'>Name</Radio>
                    <Radio value='2'>Payment Method</Radio>
                    <Radio value='3'>Note</Radio>
                </RadioGroup>
                <Flex
                    bgColor={'purple.900'}
                    borderRadius={8}
                    width={'80%'}
                    p={3}
                    mt={4}
                    justifyContent={'space-between'}
                >
                    <Checkbox
                        isChecked={isIncomesChecked}
                        onChange={handleCheckIncomes}
                        id='incomesCheck'
                        colorScheme='purple'
                    >Incomes</Checkbox>
                    <Menu>
                        <MenuButton as={Button} colorScheme='purple'>
                            {monthText}
                        </MenuButton>
                        <MenuList bgColor={'purple.900'} borderColor={'purple.900'}>
                            {months.map((item) => (
                                <MenuItem
                                    onClick={() => handleMonths(item)}
                                    bgColor={'purple.900'}
                                    _hover={{ opacity: 0.7 }}
                                >{item}</MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                    <Checkbox
                        isChecked={isExpensesChecked}
                        onChange={handleCheckExpenses}
                        id='expensesCheck'
                        colorScheme='purple'
                    >Expenses</Checkbox>
                </Flex>
                <Button
                    width={'80%'}
                    m={2}
                    mt={6}
                    bgGradient={bk1}
                    color={'#fff'}
                    onClick={handleSearch}
                    _hover={{ opacity: 0.7 }}
                >
                    Confirm <Icon icon={'mdi:check-outline'} width={20} height={20} style={{ marginLeft: 6 }} />
                </Button>
                <Flex
                    bgColor={'purple.900'}
                    borderRadius={8}
                    p={3}
                    flexDir={'column'}
                    width={'80%'}
                    alignItems={'center'}
                    mt={2}
                    mb={4}
                >
                    <Text color='lime'>Incomes : {incomesSAR()} || {incomesUSD()}$</Text>
                    <Text color='#ec1f1f'>Expenses : {expensesSAR()} || {expensesUSD()}$</Text>
                    <Text>Balance : {balanceSAR()} || {balanceUSD()}$</Text>
                </Flex>
                {/* <Select placeholder='Select option'>

                    {allDates.map((item) => (
                        <option value={item} onClick={() => setDateValue(item)}>{item}</option>
                    ))}
                </Select> */}
            </Flex>
            <Flex
                overflowY={'scroll'}
                width={{ base: '90%', md: '80%' }}
                flexDir={'column'}
                height={'400px'}
                alignSelf={'center'}
                borderRadius={8}
                overflowX={'scroll'}
                mb={20}
            >
                <TableContainer color={'#fff'} overflowY={'scroll'}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>#</Th>
                                <Th>Name</Th>
                                <Th><Icon icon={'streamline:payment-cash-out-3'} width={20} height={20} /></Th>
                                <Th>Type</Th>
                                <Th>Amount</Th>
                                <Th>Note</Th>
                                <Th><Icon icon={'clarity:date-line'} width={20} height={20} /></Th>
                                <Th></Th>

                            </Tr>
                        </Thead>

                        <Tbody>
                            {resp !== null && resp.reverse().map((item) => (
                                <Tr>
                                    <Td width={'2%'}>{item.id}</Td>
                                    <Td>{item.name}</Td>
                                    <Td>{item.paid_method}</Td>
                                    <Td>{item.type}</Td>
                                    {item.type === 'Incomes' ? (
                                        <Td color={'lime'}>+{item.amount}</Td>
                                    ) : (
                                        <Td color={'red'}>-{item.amount}</Td>
                                    )}

                                    <Td>{item.note}</Td>
                                    <Td>{formatDateString(item.created_at)}</Td>
                                    <Td width={'5%'}>
                                        <Flex>
                                            <Box
                                                onClick={() => navigate(`/edit-bill/${item.id}`)}
                                                mr={1}
                                                sx={{
                                                    transform: 'scale(0.95)',
                                                    transition: 'box-shadow 0.5s, transform 0.5s',
                                                    '&:hover': {
                                                        transform: 'scale(1.3)',
                                                    },
                                                }} as={'button'}
                                            >
                                                <Icon icon={'uil:edit'} width={30} height={30} />
                                            </Box>
                                            <Box
                                                onClick={() => handleDelete(item.id, item.note)}
                                                ml={1}
                                                sx={{
                                                    transform: 'scale(0.95)',
                                                    transition: 'box-shadow 0.5s, transform 0.5s',
                                                    '&:hover': {
                                                        transform: 'scale(1.3)',
                                                    },
                                                }} as={'button'}
                                            >
                                                <Icon icon={'mdi:note-remove-outline'} width={30} height={30} color='red' />
                                            </Box>

                                        </Flex>
                                    </Td>


                                </Tr>
                            ))}


                        </Tbody>

                    </Table>
                </TableContainer>

            </Flex>
        </>


    )
}
