
import React, { useState, useEffect } from 'react';
import { ChakraProvider, useToast, Flex, useDisclosure, Button, Modal, ModalBody, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './screens/Home';
import Navbar from './components/Navbar';
import Login from './screens/Login';
import Register from './screens/Register';
import Products from './screens/Products';
import InfoProduct from './screens/InfoProduct';
import Profile from './screens/Profile';
import { useDispatch, useSelector } from 'react-redux';
import { get_info } from './store/authSlice';
import Cookies from 'js-cookie';
import { get_products } from './store/productSlice';
import ChangePassword from './screens/ChangePassword';
import Cart from './screens/Cart';
import Dashboard from './screens/Dashboard';
import Users from './screens/Users';
import SetProfile from './screens/SetProfile';
import Paid from './screens/Paid';
import Serials from './screens/Serials';
import Logged from './screens/Logged';
import AddProduct from './screens/AddProduct';
import EditProduct from './screens/EditProduct';
import ManageProducts from './screens/ManageProducts';
import Checkout from './screens/Checkout';
import Payment from './screens/Payment';
import RegisterByPayment from './screens/RegisterByPayment';
import Orders from './screens/Orders';
import Bill from './screens/Bill';
import { bk1, bk2, bk3, bk4 } from './assets/colors';
import Footer from './components/Footer';
import BuildRequest from './screens/Free/BuildRequest';
import SplitList from './screens/Free/SplitList';
import AddWord from './screens/Free/AddWord';
import DeleteRepeated from './screens/Free/DeleteRepeated';
import SortingList from './screens/Free/SortingList';
import GetProxies from './screens/Free/GetProxies';
import AddBill from './screens/AddBill';
import EditBill from './screens/EditBill';
import Marketing from './screens/Tools/Marketing';
import ChangePasswordAdmin from './screens/ChangePasswordAdmin';
//351f4a
//1d2850
function App() {
  const dispatch = useDispatch();
  const access_token = Cookies.get("access_token");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = React.useRef(null)
  const finalRef = React.useRef(null)
  const emailRef = React.useRef(null)
  const { products } = useSelector((state) => state.product)
  const { email, isLogged, isAdmin } = useSelector((state) => state.auth)
  useEffect(() => {
    if (access_token !== undefined) {
      if (email === null) {
        try {
          dispatch(get_info(access_token));
        } catch (error) {

        }

      }

    }
    if (products === null) {
      dispatch(get_products())
    }
    if (email !== null && email === '') {

      onOpen();

    }
  }, [access_token, products, email]);

  const handleEmail = () => {
    const email_data = emailRef.current.value
    if (email_data === '') {
      toast({
        title: 'Please fill email',
        status: 'error',
        duration: 1500,
        isClosable: true,
        position: 'top',
      })
      return;
    }
    if (!email_data.includes('@')) {
      toast({
        title: 'Please enter a valid email',
        status: 'error',
        duration: 1500,
        isClosable: true,
        position: 'top',
      })
      return;
    }
    fetch('https://api.zyll.shop/update-email/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      },
      body: JSON.stringify({
        email: email_data
      })
    }).then((response) => {
      if (response.status === 200) {
        toast({
          title: 'Email updated successfully',
          status: 'success',
          duration: 1500,
          isClosable: true,
          position: 'top',
        })
        window.location.reload()
      }
    })
    onClose();
  }
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isAuthPage = location.pathname === '/register' || location.pathname === '/login';
  return (
    <ChakraProvider>

      <Flex flex={1} minH={'100vh'} width={'100%'} flexDir={'column'} alignItems={'center'} bgGradient={isHomePage ? bk2 : isAuthPage ? bk4 : bk3} >

        <Navbar />
        <>

          {email !== null && (
            <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpen}
              onClose={onClose}

            >
              <ModalOverlay />
              <ModalContent bgColor={'purple.900'} color={'#fff'}>
                <ModalHeader>You have to set your email !</ModalHeader>

                <ModalBody pb={2}>
                  <Input placeholder='Email@example.com' type='email' ref={emailRef} />
                </ModalBody>

                <ModalFooter>
                  <Button
                    bgGradient={bk1}
                    onClick={handleEmail}
                    width={'140px'}
                    color={'#fff'}
                    fontSize={20}
                    _hover={{ opacity: 0.7 }}
                  >
                    Save
                  </Button>

                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
        <Routes>
          <Route exact path="/" element={<Home />} />
          {/* <Route path="/register/:paymentID" element={<RegisterByPayment />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/products" element={<Products />} />
          <Route path="/tools" element={<Products />} />
          <Route path="/:type_page/:category" element={<Products />} />
          <Route path="/product/:id" element={<InfoProduct />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/payment/:paymentID" element={<Payment />} />
          <Route path="/build-request" element={<BuildRequest />} />
          <Route path="/split" element={<SplitList />} />
          <Route path="/add-word" element={<AddWord />} />
          <Route path="/delete-repeated" element={<DeleteRepeated />} />
          <Route path="/sorting-list" element={<SortingList />} />
          <Route path="/get-proxies" element={<GetProxies />} />
          {isLogged && (
            <>
              <Route path="/profile" element={<Profile />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/cart" element={<Cart />} />



            </>
          )}

          {isAdmin && (
            <>
              <Route path="/users" element={<Users />} />
              <Route path="/set-profile/:username" element={<SetProfile />} />
              <Route path="/paid" element={<Paid />} />
              <Route path="/serials" element={<Serials />} />
              <Route path="/logged" element={<Logged />} />
              <Route path="/add-product" element={<AddProduct />} />
              <Route path="/edit-product/:id" element={<EditProduct />} />
              <Route path="/manage-products" element={<ManageProducts />} />
              <Route path="/register" element={<Register />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/bills" element={<Bill />} />
              <Route path="/add-bill" element={<AddBill />} />
              <Route path="/edit-bill/:id" element={<EditBill />} />
              <Route path="/marketing" element={<Marketing />} />
              <Route path="/change-password-admin/:username" element={<ChangePasswordAdmin />} />
            </>
          )}

        </Routes>
        {isHomePage && <Footer />}
      </Flex>

    </ChakraProvider>
  );
}

export default App;


