import React, { useState, useEffect } from 'react'
import { Box, Flex, Text, Button, Input, TableContainer, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { Icon } from '@iconify/react';
import Cookies from 'js-cookie';
export default function Logged() {
    const [resp, setResp] = useState(null);
    const [filterdSearch, setFilterdSearch] = useState(null);
    useEffect(() => {
        if (resp === null) {
            fetch('https://api.zyll.shop/logged/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('access_token')}`
                }
            })
                .then((response) => response.json())
                .then((data) => {
                    setResp(data)
                    setFilterdSearch(data)
                })
        }
    }, [resp])
    function formatDateString(dateString) {
        const date = new Date(dateString);
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}:${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    }
    const handleSearch = (word) => {
        if (word.length >= 1) {
            let filterProducts = resp.filter(prd => (
                prd.product.toLowerCase().includes(word.toLowerCase()) ||
                prd.username.toLowerCase().includes(word.toLowerCase())
            ));
            setFilterdSearch(filterProducts);
        } else {
            setFilterdSearch(resp);
        }


    }
    return (
        <Flex
            bgColor={'#1B1639'}
            width={'80%'}
            height={'500px'}
            borderRadius={8}
            flexDir={'column'}
        >
            <Input
                placeholder='Search'
                width={'95%'}
                alignSelf={'center'}
                m={5}
                bgColor={'purple.900'}
                borderColor={'purple.900'}
                color={'#fff'}
                onChange={(e) => handleSearch(e.target.value)}
            />
            <Flex
                overflowY={'scroll'}
                width={'96%'}
                flexDir={'column'}
                height={'400px'}
                alignSelf={'center'}
                borderRadius={8}

            >
                <Flex
                    overflowY={'scroll'}
                    width={'96%'}
                    flexDir={'column'}
                    height={'400px'}
                    alignSelf={'center'}
                    borderRadius={8}
                    overflowX={'scroll'}
                >
                    <TableContainer color={'#fff'}>
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>#</Th>
                                    <Th><Icon icon={'mdi:user'} width={20} height={20} /></Th>
                                    <Th><Icon icon={'icon-park-outline:ad-product'} width={20} height={20} /></Th>
                                    <Th><Icon icon={'clarity:date-line'} width={20} height={20} /></Th>

                                </Tr>
                            </Thead>

                            <Tbody>
                                {filterdSearch !== null && filterdSearch.reverse().map((item) => (
                                    <Tr>
                                        <Td>{item.id}</Td>
                                        <Td>{item.username}</Td>
                                        <Td>{formatDateString(item.created_at)}</Td>
                                        <Td>{item.serial}</Td>


                                    </Tr>
                                ))}


                            </Tbody>

                        </Table>
                    </TableContainer>

                </Flex>

            </Flex>
        </Flex>
    )
}
