import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import Cookies from "js-cookie";



export const get_products = createAsyncThunk('product/products', async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    const access_token = Cookies.get('access_token');
    let headers = {
        'Content-Type': 'application/json',

    }
    if (access_token !== undefined) {
        headers['Authorization'] = `Bearer ${access_token}`;
    }
    try {
        const response = await axios.get('https://api.zyll.shop/products/', {
            headers: headers
        });
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.message);
    }
});


const initialState = {
    isLoading: false,
    products: null,
    oldProducts: null,
    categories: ['Categories']

}
const productSlice = createSlice({
    name: "product",
    initialState,
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(get_products.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(get_products.fulfilled, (state, action) => {

                state.isLoading = false;
                state.products = action.payload;
                state.oldProducts = action.payload;

                state.categories = ['All', ...new Set(action.payload.map((item) => item.category))];
            })
            .addCase(get_products.rejected, (state) => {
                state.isLoading = false;
            })
    }
})
export const { setProducts } = productSlice.actions;
export default productSlice.reducer;
