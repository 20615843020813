import React, { useState, useEffect, useRef } from 'react'
import { Flex, Text, Input, Checkbox, Radio, RadioGroup, Stack, Button, Box, Skeleton } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import { bk1 } from '../assets/colors';
export default function Checkout() {
    const location = useLocation();
    const navigate = useNavigate();
    const { IDS, total } = location.state || {};
    const [crypto, setCrypto] = useState('ltc');
    const [btnText, setBtnText] = useState('Create Payment');
    const access_token = Cookies.get('access_token');
    const userRef = useRef('')
    const handlePayment = () => {
        const data = {
            id: IDS,
            crypto: crypto
        }
        if (access_token === undefined) {
            data['username'] = userRef.current.value;
        }



        setBtnText('Creating Payment ...');
        console.log(data)
        const headers = {
            'Content-Type': 'application/json',
        }
        if (access_token !== undefined) {
            headers['Authorization'] = `Bearer ${access_token}`
        }
        fetch('https://api.zyll.shop/create-payment/', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((resp) => {
                const paymentID = resp.payment_id;
                if (paymentID !== '' && paymentID !== undefined) {
                    navigate(`/payment/${paymentID}`);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error While Creating Payment',
                        text: 'Please try again!',
                        confirmButtonColor: '#f27474'
                    })
                }


            })
    }

    return (
        <Flex
            width={{ base: '90%', md: '40%' }}
            pb={4}
            flexDir={'column'}
            bgColor={'#1B1639'}
            color={'#fff'}
            mt={10}
            borderRadius={8}
            alignItems={'center'}
        >
            <Text mt={3} fontSize={22} fontWeight={'bold'}>Choose crypto : </Text>
            <RadioGroup onChange={setCrypto} value={crypto} width={'100%'} mt={5} colorScheme='purple'>
                <Flex width={'100%'} flexDir={'column'} alignItems={'center'}>
                    <Radio value='ltc' ><Icon color='#335a97' icon="cryptocurrency:ltc" width="30" height="30" /></Radio>

                    <Radio value='btc' mt={3}><Icon color='#f79626' icon="cryptocurrency:btc" width="30" height="30" /></Radio>
                    {/* <Radio value='eth' mt={3}><Icon color='#6882eb' icon="cryptocurrency:eth" width="30" height="30" /></Radio> */}
                    <Radio value='usdttrc20' mt={3}><Icon color='#56b298' icon="cryptocurrency:usdt" width="30" height="30" /></Radio>
                </Flex>
            </RadioGroup>
            <Text mt={3} fontSize={22}>Amount : {total}$</Text>
            {access_token === undefined && <Input
                placeholder='Your Instagram Username'
                mt={5}
                width={'80%'}
                ref={userRef}
                bgColor={'purple.900'}
                borderColor={'purple.900'}
            />}
            {btnText !== '' && <Button
                mt={5}
                bgGradient={bk1}
                color={'#fff'}
                _hover={{ opacity: 0.7 }}
                onClick={handlePayment}

            >{btnText}</Button>}
        </Flex>
    )
}
